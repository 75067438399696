import { useNavigate } from "react-router";
import { Expand } from "../../parts/Expand";
import { useState } from "react";
import Config from "../../Config";
import { error } from "console";
import { Link } from "react-router-dom";

export function PromoCodePage() {
  const navigate = useNavigate();

  const [promoResponseFromApi, setPromoResponse] = useState<
    { promoCode: string; index: number } | undefined
  >(undefined);

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalErrorAndText, setModalErrorAndText] = useState({
    error: false,
    text: "",
  });

  function checkPromoCode(promoCodeInput: string) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ code: promoCodeInput }),
    };

    fetch(Config.endpoint + "puzzle/all", requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          setModalErrorAndText({ error: true, text: "Erreur dans la requête" });
          setModalOpen(true);
        }
        if (response.status !== 200) {
          setModalErrorAndText({
            error: true,
            text:
              response.status === 410
                ? (await response.json()).error
                : response.status === 404
                ? "Ce code n'est pas une solution"
                : "Erreur dans l'envoi du code",
          });
          setModalOpen(true);
        } else {
          setPromoResponse(await response.json());
        }
      })
      .catch((e) => {
        setModalErrorAndText({ error: true, text: "Erreur de validation" });
        setModalOpen(true);
      });
  }

  return (
    <>
      <div
        className="column is-half-desktop is-offset-one-quarter-desktop is-centered text-center"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginBottom: "8rem",
        }}
      >
        {!promoResponseFromApi ? (
          <PromoCodeForm checkPromoCode={checkPromoCode} />
        ) : (
          <div>
            <h1 className="light-h1" style={{ marginBottom: "0.7rem" }}>
              Félicitations !
            </h1>
            <div style={{ marginBottom: "0.5rem" }}>
              {promoResponseFromApi.index < 1 ? (
                <p>
                  Voici un lien qui vous donnera accès à une copie gratuite du
                  jeu pour Lyon
                </p>
              ) : (
                <p>
                  Les copies gratuites sont écoulées mais le lien suivant vous
                  permet de profiter du jeu à 9,50€
                </p>
              )}
            </div>
            <Link
              to={
                Config.stripe?.lyon?.url +
                `?prefilled_promo_code=${promoResponseFromApi.promoCode}`
              }
              className="button is-danger"
              type="submit"
              style={{ backgroundColor: "#e25571" }}
            >
              Accéder à la page d'achat
            </Link>
          </div>
        )}

        <div
          id="newsletter-modal"
          className={`modal is-${modalErrorAndText.error ? "error" : "ok"} ${
            isModalOpen ? " is-active" : ""
          }`}
          onClick={() => setModalOpen(false)}
          style={{ textAlign: "center" }}
        >
          <div className="modal-background"></div>

          <div className="modal-content">
            <div className="box">
              <p>{modalErrorAndText.text}</p>
            </div>
          </div>

          <button className="modal-close is-large" aria-label="close"></button>
        </div>
      </div>
      <Expand />
      <div
        className="column is-half-desktop is-offset-one-quarter-desktop is-centered"
        style={{ flexGrow: 0 }}
      >
        <button
          className="button is-off is-centered"
          style={{
            margin: "1rem auto auto auto",
            minWidth: "15rem",
            width: "90%",
            display: "block",
          }}
          onClick={() => navigate(-1)}
        >
          Retour
        </button>
      </div>
    </>
  );
}

function PromoCodeForm({
  checkPromoCode,
}: {
  checkPromoCode: (p: string) => void;
}) {
  const [promoCodeInput, setPromoCodeInput] = useState<string>("");

  return (
    <div>
      <h1 className="light-h1" style={{ marginBottom: "1rem" }}>
        Vous pensez connaître un code ?
      </h1>
      <form
        className="field has-addons"
        onSubmit={(e) => {
          e.preventDefault();
          checkPromoCode(promoCodeInput);
        }}
        style={{ justifyContent: "center" }}
      >
        <div className="control">
          <input
            className="input"
            type="text"
            size={30}
            value={promoCodeInput}
            onChange={(e) => setPromoCodeInput(e.target.value)}
            name="promoCode"
            placeholder="Indiquez la solution de l'énigme"
          />
        </div>
        <div className="control">
          <button
            className="button is-danger"
            type="submit"
            style={{ backgroundColor: "#e25571" }}
          >
            Valider
          </button>
        </div>
      </form>
    </div>
  );
}
