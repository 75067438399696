import { useState } from "react";
import { CodeFormProps } from "../CodeFormInfos";
import { Link } from "react-router-dom";

export function CodeFormLinks(p: CodeFormProps) {
  const [currentCode, setCurrentCode] = useState<string>("");

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        p.checkCode(currentCode);
      }}
      style={{ marginBottom: "2vh" }}
    >
      {p.errorMessage ? (
        <h2 className="is-family-monospace login-tooltip is-nok">
          {p.errorMessage}
        </h2>
      ) : (
        <></>
      )}
      <div className="field code-app-field">
        <div className="control">
          <input
            className="input is-family-monospace"
            name="code"
            value={currentCode}
            onChange={(e) => setCurrentCode(e.target.value)}
            placeholder={p.formInfos.placeholder}
            maxLength={p.formInfos.maxLength}
            minLength={p.formInfos.minLength}
            type={p.formInfos.type}
            max={
              p.formInfos.type === "number" || p.formInfos.type === "range"
                ? p.formInfos.max
                : undefined
            }
            min={
              p.formInfos.type === "number" || p.formInfos.type === "range"
                ? p.formInfos.min
                : undefined
            }
          />
        </div>
        <div className="control">
          <button className="button is-fullwidth is-family-monospace is-active">
            VALIDER
          </button>
        </div>
      </div>
      <div
        style={{
          marginTop: "3rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {p.formInfos.links ? (
          p.formInfos.links.map((v, i) => (
            <Link
              key={i}
              className="button is-fullwidth button-cartel-light"
              to={v.to}
            >
              {v.str}
            </Link>
          ))
        ) : (
          <></>
        )}
      </div>
    </form>
  );
}
