import { Link } from "react-router-dom";
import Logo from "../imgs/logo.png";
import { useState } from "react";
import { TutorialSteps } from "../redux/tutorials/TutorialState";
import { TutorialPopup } from "./TutorialPopup";
import { useSelector } from "react-redux";
import { RootState } from "../redux/ReduxStore";
import { DefaultCluePopup } from "../pages/home/body/clue/DefaultCluePopup";

export function NavbarTutorial() {
  return (
    <TutorialPopup
      step={TutorialSteps.Navbar}
      style={{
        right: "2vw",
        top: "120%",
        minWidth: "300px",
        width: "33vw",
        maxWidth: "80vw",
      }}
    >
      <p>
        Si vous êtes bloqués, utilisez le bouton <b>en haut à droite</b> pour
        afficher des indices
      </p>
    </TutorialPopup>
  );
}

export function Navbar() {
  const [popupVisible, setPopupVisible] = useState(false);
  const showClueButton = useSelector(
    (state: RootState) => state.showClue.value
  );

  const crossSize = "2.5rem";
  const sizeStyle = (size: string | number): React.CSSProperties => {
    return {
      height: size,
      width: size,
      minWidth: size,
      minHeight: size,
      maxHeight: size,
      maxWidth: size,
    };
  };

  return (
    <nav
      className="navbar is-black"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <img
          src={Logo}
          alt="Logo de la compagnie"
          className="navbar-item"
          style={{ objectFit: "contain" }}
        />
        <Link to="/home" className="navbar-item">
          La Compagnie
        </Link>
      </div>

      <div className="navbar-end">
        <div className="navbar-item">
          <div className="buttons">
            {showClueButton ? (
              <>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setPopupVisible(!popupVisible);
                  }}
                  className="button navbar-button is-rounded"
                  style={{
                    fontWeight: 700,
                  }}
                >
                  <span className="above-700px">Besoin d'aide ?</span>
                  <span className="above-500px under-700px">Indices</span>
                  <span className="under-500px">Indices</span>
                </button>
                <NavbarTutorial />
                <div className={"modal " + (popupVisible ? "is-active" : "")}>
                  <div
                    className="modal-background"
                    onClick={(e) => {
                      e.preventDefault();
                      setPopupVisible(!popupVisible);
                    }}
                  ></div>
                  <div className="modal-content">
                    <div className="box">
                      <DefaultCluePopup />
                    </div>
                  </div>
                  <button
                    className="modal-close button is-rounded navbar-button"
                    aria-label="close"
                    onClick={(e) => {
                      e.preventDefault();
                      setPopupVisible(!popupVisible);
                    }}
                    style={{
                      fontWeight: 800,
                      ...sizeStyle(crossSize),
                    }}
                  ></button>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}
