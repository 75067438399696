import { createAction, createReducer } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./ReduxStore";

export function useUpdateShowClue(value: boolean | null) {
  const dispatch = useDispatch();
  const showClueValue = useSelector((state: RootState) => state.showClue.value);

  if (value !== null && value !== showClueValue) {
    dispatch(setShowClue(value));
  }
}

export type ShowClueState = { value: boolean };
const setShowClue = createAction<boolean>("setShowClue");

export const ShowClueReducer = createReducer<ShowClueState>(
  { value: false },
  (builder) => {
    builder.addCase(setShowClue, (state, action) => {
      state.value = action.payload;
    });
  }
);
