import { useState } from "react";
import { CodeFormProps } from "../CodeFormInfos";

export function CodeFormGPS(p: CodeFormProps) {
  const [currentCodes, setCurrentCodes] = useState<number[]>([1, 0, 0, 0]);

  function setCodeIdx(idx: number, diff: number) {
    const value = currentCodes[idx] + diff;
    var realValue = value < 0 ? 9 : value > 9 ? 0 : value;
    var nextCodes = [...currentCodes];
    nextCodes[idx] = realValue;
    setCurrentCodes(nextCodes);
  }

  const OneButton = (but: { idx: number; disabled?: boolean }) => (
    <div>
      <div className="control">
        <button
          className="button is-fullwidth is-family-monospace is-active is-top is-pale"
          disabled={but.disabled}
          onClick={(e) => {
            e.preventDefault();
            setCodeIdx(but.idx, 1);
          }}
        >
          +
        </button>
      </div>
      <div className="control">
        <p className="input is-fullwidth is-family-monospace no-radius">
          {currentCodes[but.idx]}
        </p>
      </div>
      <div className="control">
        <button
          className="button is-fullwidth is-family-monospace is-active is-pale"
          disabled={but.disabled}
          onClick={(e) => {
            e.preventDefault();
            setCodeIdx(but.idx, -1);
          }}
        >
          -
        </button>
      </div>
    </div>
  );

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {p.errorMessage ? (
          <h2 className="is-family-monospace login-tooltip is-nok">
            {p.errorMessage}
          </h2>
        ) : (
          <></>
        )}
        <div className="field code-app-field inline-buttons">
          <div>
            <div className="control">
              <button
                className="button is-fullwidth is-family-monospace is-active is-left"
                disabled={true}
              >
                &nbsp;
              </button>
            </div>
          </div>
          <div className="core-buttons">
            <OneButton idx={0} disabled={true} />
            <OneButton idx={1} />
            <OneButton idx={2} />
            <OneButton idx={3} />
          </div>
          <div>
            <div className="control">
              <button
                className="button is-fullwidth is-family-monospace is-active is-right"
                disabled={true}
              >
                &nbsp;
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className="bottom-part">
        <h2>Coordonnées</h2>
        <div className="text-encart is-family-monospace text-center">
          <p className="text-center">
            45°46'09.<b>{currentCodes[1]}</b>"N
          </p>
          <p className="text-center">
            &nbsp;4°50'0<b>{currentCodes[2]}</b>.<b>{currentCodes[3]}</b>"E
          </p>
        </div>
      </div>
      <div className="bottom-part" style={{ marginBottom: "10vh" }}>
        <button
          className="button is-active"
          onClick={(e) => {
            e.preventDefault();
            const codeToCheck = currentCodes.reduce((p, curr) => p + curr, "");
            p.checkCode(codeToCheck);
          }}
        >
          Passer à l'étape suivante
        </button>
      </div>
    </>
  );
}
