import { DocumentPage } from "../DocumentsElements";
import "../styles/A4.scss";
import "../styles/A4unset.scss";
import map from "./CarteLyon.png";

export function LyonMapDocument() {
  return (
    <DocumentPage>
      <img src={map} alt="Carte de Lyon" />
    </DocumentPage>
  );
}
