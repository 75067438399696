import { Outlet, useNavigate } from "react-router";
import Header, { HeaderProps } from "../parts/Header";
import Footer from "../parts/Footer";
import "../styles/classicPadded.scss";
import { AuthThunks } from "../redux/auth/AuthThunk";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/ReduxStore";

export function HeaderFooterStructure(props: HeaderProps) {
  const authState = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const token = Cookies.get("token");

  if (!authState.initialized && !authState.loading && token) {
    dispatch(AuthThunks.check());
  }

  if (authState.logged) {
    navigate("/home");
  }

  return (
    <div className="classicPadded">
      <Header {...props}></Header>
      <Outlet />
      <Footer></Footer>
    </div>
  );
}
