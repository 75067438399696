import { splitToSpan, validateEmail } from "../../tools/TextTools";
import { CSSProperties, useState } from "react";
import LandingConstants from "./LandingConstants";
import Config from "../../Config";
import { Link } from "react-router-dom";
import { Expand } from "../../parts/Expand";

interface Props {
  useDelay: boolean;
}

function NewsletterForm({ useDelay }: Props) {
  const ErrorMessages: { [key: string]: string } = {
    INVALID_EMAIL: "Le mail indiqué est invalide",
    MISSING_EMAIL: "Merci de renseigner votre mail",
    DUPLICATE_EMAIL: "Le mail indiqué est déjà inscrit",
    TOO_MANY_REQUESTS: "Veuillez attendre avant d'effectuer une inscription",
  };

  const delay =
    LandingConstants.baseDelay +
    LandingConstants.tagLineDuration +
    LandingConstants.taglineDelay +
    LandingConstants.imageDuration +
    LandingConstants.imageDelay;

  let style: CSSProperties = {
    WebkitAnimationDelay: delay + "s",
    animationDelay: delay + "s",
    animationDuration: LandingConstants.newsletterDuration + "s",
    WebkitAnimationDuration: LandingConstants.newsletterDuration + "s",
  };

  const [emailValue, setEmailValue] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalErrorAndText, setModalErrorAndText] = useState({
    error: false,
    text: "",
  });

  async function sendEmail() {
    if (!validateEmail(emailValue)) {
      setModalErrorAndText({ error: true, text: ErrorMessages.INVALID_EMAIL });
      setModalOpen(true);
      return;
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: emailValue }),
    };
    const response = await fetch(
      Config.endpoint + "newsletter/",
      requestOptions
    );

    if (!response.ok) {
      var futureModalText = "";
      if (response.status === 429) {
        futureModalText = ErrorMessages.TOO_MANY_REQUESTS;
      } else {
        const data = await response.json();
        const errorKey: string = data.error;
        futureModalText =
          errorKey in ErrorMessages ? ErrorMessages[errorKey] : errorKey;
      }
      setModalErrorAndText({ error: true, text: futureModalText });
      setModalOpen(true);
    } else {
      setModalErrorAndText({
        error: false,
        text: "Enregistrement réussi. A bientôt !",
      });
      setModalOpen(true);
    }
  }

  const lyonInfos = Config.stripe.lyon;

  return (
    <section
      className="section is-mobile is-centered bottomPart appear"
      style={useDelay ? style : {}}
    >
      <div
        className="columns is-mobile is-centered is-fullwidth"
        style={{ padding: "0 0.75rem", marginTop: ".1rem" }}
      >
        {lyonInfos ? (
          <Link
            className="is-fullwidth button is-danger is-medium big"
            type="submit"
            to={
              lyonInfos.url +
              (lyonInfos.promoCode
                ? `?prefilled_promo_code=${lyonInfos.promoCode}`
                : "")
            }
            style={{ overflow: "hidden", backgroundColor: "#ef2e55" }}
          >
            {lyonInfos.promoCode ? "Précommandez" : "Commandez"}
            {" le pack "}
            <span style={{ fontWeight: 700, padding: "0 5pt" }}>LYON</span>
            {lyonInfos.promoCode ? (
              <span className="above-500px">pour Juin 2024</span>
            ) : (
              <></>
            )}
          </Link>
        ) : (
          <></>
        )}
      </div>

      {lyonInfos ? (
        <div className="is-fullwidth is-centered text-center columns">
          <p
            style={{
              fontStyle: "",
              fontSize: "80%",
              marginBottom: "-0.3rem",
            }}
          >
            {lyonInfos.promoCode ? (
              <>
                <span className="under-500px">Sortie Juin 2024, </span>
                <span style={{ fontWeight: "" }}>
                  Offre Lancement à 14,50€
                </span>{" "}
                (<span style={{ textDecoration: "line-through" }}>19,50€</span>,{" "}
                <Link className="lightLink" to="/cgv">
                  voir CGV
                </Link>
                )
              </>
            ) : (
              <>
                <span style={{ fontWeight: "" }}>Prix : 19,50€</span> (
                <Link className="lightLink" to="/cgv">
                  voir CGV
                </Link>
                )
              </>
            )}
          </p>
        </div>
      ) : (
        <></>
      )}
      <Expand />
      {Config.showCodeButton ? (
        <div className="columns is-mobile is-centered">
          <Link
            to="/code"
            className="button is-danger"
            style={{ backgroundColor: "#e25571" }}
          >
            Vous avez résolu une enquête sur nos réseaux ?
          </Link>
        </div>
      ) : (
        <></>
      )}

      <Expand />
      <div className="comingSoon">
        <div className="tagline cities">
          <p
            style={{
              fontStyle: "italic",
              fontWeight: "700",
              fontSize: "80%",
              marginBottom: "-0.3rem",
            }}
          >
            Prochainement
          </p>
          <h2 className="">
            {!lyonInfos ? (
              <>
                <span>·</span>
                <span>&nbsp;</span>
                <span className="city lyon">{splitToSpan("LYON")}</span>
                <span>&nbsp;</span>
              </>
            ) : (
              <></>
            )}
            <span>·</span>
            <span>&nbsp;</span>
            <span className="city toulouse">{splitToSpan("TOULOUSE")}</span>
            <span>&nbsp;</span>
            <span>·</span>
            <span>&nbsp;</span>
            <span className="city paris">{splitToSpan("PARIS")}</span>
            <span>&nbsp;</span>
            <span>·</span>
          </h2>
        </div>
        <div className="columns is-mobile is-centered">
          <div className="column is-narrow is-centered form-email">
            <form
              className="field has-addons"
              onSubmit={(e) => {
                e.preventDefault();
                sendEmail();
              }}
              style={{ marginTop: "0.1rem" }}
            >
              <div className="control">
                <input
                  className="input"
                  type="email"
                  size={30}
                  value={emailValue}
                  onChange={(e) => setEmailValue(e.target.value)}
                  name="email"
                  placeholder="Entrez votre email"
                />
              </div>
              <div className="control">
                <button
                  className="button is-danger"
                  type="submit"
                  style={{ backgroundColor: "#e25571" }}
                >
                  Nous suivre
                </button>
                <div
                  id="newsletter-modal"
                  className={`modal is-${
                    modalErrorAndText.error ? "error" : "ok"
                  } ${isModalOpen ? " is-active" : ""}`}
                  onClick={() => setModalOpen(false)}
                  style={{ textAlign: "center" }}
                >
                  <div className="modal-background"></div>

                  <div className="modal-content">
                    <div className="box">
                      <p>{modalErrorAndText.text}</p>
                    </div>
                  </div>

                  <button
                    className="modal-close is-large"
                    aria-label="close"
                  ></button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NewsletterForm;
