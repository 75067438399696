import { createAction, createReducer } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router";
import { RootState } from "./ReduxStore";
import { LoadingPage } from "../pages/LoadingPage";

export function StoreActivationCodeAndRedirect() {
  const { activationCode } = useParams();
  const dispatch = useDispatch();
  const stateActivationCode = useSelector(
    (state: RootState) => state.activationCode.value
  );

  if (activationCode && activationCode !== stateActivationCode) {
    dispatch(setActivationCode(activationCode));
    return <LoadingPage />;
  } else {
    return <Navigate to="/home" />;
  }
}

export type ActivationCodeState = { value: string | null };
const setActivationCode = createAction<string>("setActivationCode");
export const unsetActivationCode = createAction("unsetActivationCode");

export const ActivationCodeReducer = createReducer<ActivationCodeState>(
  { value: null },
  (builder) => {
    builder
      .addCase(setActivationCode, (state, action) => {
        state.value = action.payload;
      })
      .addCase(unsetActivationCode, (state, action) => {
        state.value = null;
      });
  }
);
