import { Navigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../ReduxStore";
import { LoadingPage } from "../../pages/LoadingPage";
import Cookies from "js-cookie";
import { AuthThunks } from "./AuthThunk";
import { StatusThunk } from "../status/StatusThunk";

export const AuthRoute = ({ children }: { children: React.ReactNode }) => {
  const authState = useSelector((state: RootState) => state.auth);
  const statusState = useSelector((state: RootState) => state.status);

  const dispatch = useDispatch<AppDispatch>();

  const token = Cookies.get("token");

  if (!authState.initialized && !authState.loading && token) {
    dispatch(AuthThunks.check());
  }

  if (!statusState.initialized && !statusState.loading) {
    dispatch(StatusThunk.currentStatus());
  }

  const location = useLocation();

  if (!authState.initialized && token) {
    return <LoadingPage />;
  } else if (!authState.logged) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  } else {
    return children;
  }
};

export const AdminRoute = ({ children }: { children: React.ReactNode }) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const location = useLocation();

  if (user && !user.admin) {
    return <Navigate to="/" replace state={{ from: location }} />;
  } else {
    return <AuthRoute>{user && user.admin ? children : <></>}</AuthRoute>;
  }
};
