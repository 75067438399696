import "../MessagesBase.scss";
import { BaseMessage } from "../BaseMessage";

export function LyonMessage5CleUsb() {
  return (
    <BaseMessage title="La Clé Usb">
      <p>Vous touchez au but.</p>
      <p>
        Nous ne pensions pas avoir besoin de vous l'annoncer mais nous avions
        trouvé d'autres documents chez HENRI. Principalement, un courrier et une
        clé USB chiffrée qui semblent provenir du même informateur.
      </p>
      <p>
        Nous ne pensions pas avoir besoin de vous communiquer ce mot mais il
        semble mentionner les fréquences que vous venez de trouver. Nous l'avons
        donc ajouté à votre espace.
      </p>
      <p>
        Nous avons censuré la fin du message jusqu'à ce que vous trouviez le
        code de cette clé USB.
      </p>
      <p>
        <br />
      </p>
      <p>
        Ne nous décevez pas,
        <br />
        <span className="italic bold">La Compagnie</span>
      </p>
    </BaseMessage>
  );
}
