import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit'
import AuthSlice, { AuthState, AuthStateInitial } from './auth/AuthSlice'
import StatusSlice, { StatusState, StatusStateInitial } from './status/StatusSlice'
import { ActivationCodeReducer, ActivationCodeState } from './ActivationCode'
import { AdminReducer, AdminState } from './admin/AdminState'
import { TutorialReducer, TutorialState } from './tutorials/TutorialState'
import { ShowClueReducer } from './ShowClue'

const appReducer = combineReducers({ auth: AuthSlice, status: StatusSlice, activationCode: ActivationCodeReducer, admin: AdminReducer, tutorial: TutorialReducer, showClue: ShowClueReducer })

export const RESET_ACTION = {
    type: "RESET_STATE"
}

const rootReducer = (state: { auth: AuthState, status: StatusState, activationCode: ActivationCodeState, admin: AdminState, tutorial: TutorialState }, action: AnyAction) => {
    switch (action.type) {
        case RESET_ACTION.type:
            return appReducer({ auth: AuthStateInitial, status: StatusStateInitial, activationCode: { value: null }, admin: {}, tutorial: {}, showClue: { value: false } }, action)
        default:
            return appReducer(state, action)
    }
}


export const ReduxStore = configureStore({
    reducer: (state, action) => rootReducer(state, action),
})

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof ReduxStore.dispatch