import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../redux/ReduxStore";
import {
  TutorialSteps,
  nextTutorialAction,
} from "../redux/tutorials/TutorialState";
import { ReactNode } from "react";

export function TutorialPopup({
  style,
  step,
  children,
}: {
  style: React.CSSProperties | undefined;
  step: TutorialSteps;
  children: ReactNode;
}) {
  const isTuto = useSelector(
    (state: RootState) =>
      state.tutorial && state.tutorial.step && state.tutorial.step === step
  );
  const dispatch = useDispatch();

  return isTuto ? (
    <>
      <div className="popup-tutorial-background">&nbsp;</div>
      <div className="popup-tutorial" style={style}>
        <div>{children}</div>
        <button
          className="button is-active is-right"
          onClick={(e) => {
            e.preventDefault();
            dispatch(nextTutorialAction());
          }}
        >
          Suivant
        </button>
      </div>
    </>
  ) : (
    <></>
  );
}
