import { Transition } from "react-transition-group";
import { TransitionProps } from "react-transition-group/Transition";
import styled from "styled-components";

const FadeDiv = styled.div<{ state: string }>`
  transition: 0.08s;
  opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
  display: ${({ state }) => (state === "exited" ? "none" : "block")};
`;

export const FadeTransition = ({
  children,
  ...rest
}: (TransitionProps | { key: string }) & { children: React.ReactNode }) => (
  <Transition timeout={70} unmountOnExit mountOnEnter {...rest}>
    {(state) => <FadeDiv state={state}>{children}</FadeDiv>}
  </Transition>
);
