import { useCallback, useEffect, useState } from "react";
import { CodeFormProps } from "../CodeFormInfos";
import { randomNumberInRange } from "../../../tools/Random";
import { useTimerPercent } from "../../../tools/TimeTools";

export const LyonTextsMessageStatue = [
  "Je crois avoir raison de vous faire confiance. Seulement, il m'est impossible de vous rencontrer ici. Nous ne devons en aucun cas attirer l'attention !",
  "Depuis votre position, vous devez voir un grand disque. Derrière lui suivez le chemin du gardien ailé.",
  "Au bout de 20m, une autre voie s'ouvre, suivez là jusqu'au bout. Une dame perchée saura vous aider à préciser les coordonnées pour notre rendez-vous.",
  "Ignorez son premier mot et retrouvons nous ici: 45°46'09.X\"N 4°50'0X.X\"E",
];

const CharsCodes = {
  A: "A".charCodeAt(0),
  Z: "Z".charCodeAt(0),
  a: "a".charCodeAt(0),
  z: "z".charCodeAt(0),
  n0: "0".charCodeAt(0),
  n9: "9".charCodeAt(0),
  nDeg: "°".charCodeAt(0),
  nSQ: "'".charCodeAt(0),
  nEx: "!".charCodeAt(0),
  nSlash: "/".charCodeAt(0),
};

function randomizeEncart(textsEncarts: string[]): string[] {
  return textsEncarts.map((sentence) =>
    Array.from(sentence)
      .map((character) => {
        const code = character.charCodeAt(0);
        if (
          (code >= CharsCodes.A && code <= CharsCodes.Z) ||
          (code >= CharsCodes.n0 && code <= CharsCodes.n9) ||
          (code >= CharsCodes.nEx && code <= CharsCodes.nSlash) ||
          [CharsCodes.nDeg, CharsCodes.nSQ].includes(code)
        ) {
          return String.fromCharCode(
            randomNumberInRange(CharsCodes.A, CharsCodes.Z)
          );
        } else if (code >= CharsCodes.a && code <= CharsCodes.z) {
          return String.fromCharCode(
            randomNumberInRange(CharsCodes.a, CharsCodes.z)
          );
        } else {
          return character;
        }
      })
      .join("")
  );
}

export function BottomLoading({
  tryDate,
  isCorrect,
  onSuccess,
}: {
  tryDate?: Date;
  isCorrect: boolean;
  onSuccess: () => void;
}) {
  const percentTotal = useTimerPercent(
    tryDate ? tryDate : new Date(),
    5 / 60,
    70
  );
  const [alreadySent, setAlreadySent] = useState(false);

  if (percentTotal >= 100 && isCorrect && !alreadySent) {
    onSuccess();
    setAlreadySent(true);
  }

  const percent = 100 * (percentTotal / 85);

  function calcRadius() {
    const limit = 90;
    return percent < limit ? 0 : (0.8 * (percent - limit)) / (100 - limit);
  }

  return (
    <>
      <div
        style={{
          position: "relative",
          bottom: "10px",
          background: percent < 100 || !isCorrect ? "red" : undefined,
          width: (percent < 100 ? percent : 100) + "%",
          margin: "0 auto 0 auto",
          height: "10px",
          opacity: tryDate ? 1 : 0,
          borderBottomLeftRadius: calcRadius() + "rem",
          borderBottomRightRadius: calcRadius() + "rem",
          ...(isCorrect && percent > 95
            ? {
                animationName: "redGreenFade",
                animationDuration: "0.5s",
                animationFillMode: "forwards",
              }
            : {}),
        }}
      >
        &nbsp;
      </div>
      {percent < 100 ? (
        <></>
      ) : (
        <div
          className="bottom-part is-family-monospace slideDown is-fullwidth text-center"
          style={{
            margin: "auto",
            position: "absolute",
            left: 0,
            right: 0,
            fontSize: "80%",
          }}
        >
          Code {isCorrect ? "Correct" : "Erroné"}
        </div>
      )}
    </>
  );
}

export function CodeFormStatue(p: CodeFormProps) {
  const [currentCodes, setCurrentCodes] = useState<number[]>([0, 0, 0, 0]);
  const [currentTextsEncarts, setCurrentTextsEncarts] = useState<string[]>(
    randomizeEncart(LyonTextsMessageStatue)
  );
  const [isEncartCorrect, setIsEncartCorrect] = useState<boolean>(false);
  const [codeHasChanged, setCodeHasChanged] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [tryDate, setTryDate] = useState<Date | undefined>(undefined);

  const checkCurrentCode = useCallback(() => {
    const codeToCheck = currentCodes.reduce((p, curr) => p + curr, "");
    if (codeToCheck === "1832") {
      setIsEncartCorrect(true);
      setCurrentTextsEncarts(LyonTextsMessageStatue);
    } else {
      setIsEncartCorrect(false);
      setShowConfirm(false);
      setCurrentTextsEncarts(randomizeEncart(LyonTextsMessageStatue));
    }

    if (codeToCheck !== "0000" || codeHasChanged) {
      setCodeHasChanged(true);
      setTryDate(new Date());
    }
  }, [currentCodes, codeHasChanged]);

  useEffect(() => {
    checkCurrentCode();
  }, [currentCodes, checkCurrentCode]);

  if (p.correct && !isEncartCorrect) {
    setIsEncartCorrect(true);
    setCurrentTextsEncarts(LyonTextsMessageStatue);
  }

  function setCodeIdx(idx: number, diff: number) {
    const value = currentCodes[idx] + diff;
    var realValue = value < 0 ? 9 : value > 9 ? 0 : value;
    var nextCodes = [...currentCodes];
    nextCodes[idx] = realValue;
    setCurrentCodes(nextCodes);
  }

  const OneButton = (but: { idx: number }) => (
    <div>
      <div className="control">
        <button
          className="button is-fullwidth is-family-monospace is-active is-top is-pale"
          disabled={p.correct}
          onClick={(e) => {
            e.preventDefault();
            setCodeIdx(but.idx, 1);
          }}
        >
          +
        </button>
      </div>
      <div className="control">
        <p className="input is-fullwidth is-family-monospace no-radius">
          {currentCodes[but.idx]}
        </p>
      </div>
      <div className="control">
        <button
          className="button is-fullwidth is-family-monospace is-active is-pale"
          disabled={p.correct}
          onClick={(e) => {
            e.preventDefault();
            setCodeIdx(but.idx, -1);
          }}
        >
          -
        </button>
      </div>
    </div>
  );

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          checkCurrentCode();
        }}
      >
        {p.errorMessage ? (
          <h2 className="is-family-monospace login-tooltip is-nok">
            {p.errorMessage}
          </h2>
        ) : (
          <></>
        )}
        <div className="field code-app-field inline-buttons">
          <div>
            <div className="control">
              <button
                className="button is-fullwidth is-family-monospace is-active is-left"
                disabled={p.correct}
                type="submit"
              >
                ↳
              </button>
            </div>
          </div>
          <div className="core-buttons">
            <OneButton idx={0} />
            <OneButton idx={1} />
            <OneButton idx={2} />
            <OneButton idx={3} />
          </div>
          <div>
            <div className="control">
              <button
                className="button is-fullwidth is-family-monospace is-active is-right"
                disabled={p.correct}
                type="submit"
              >
                ↲
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className="bottom-part">
        <h2>Message</h2>
        <div>
          <div className="text-encart is-family-monospace ">
            {currentTextsEncarts.map((t) => (
              <p>{t}</p>
            ))}
          </div>
        </div>
      </div>

      <BottomLoading
        tryDate={tryDate}
        isCorrect={isEncartCorrect}
        onSuccess={() => {
          setShowConfirm(true);
        }}
      />
      {showConfirm ? (
        <button
          className="button is-active"
          onClick={(e) => {
            e.preventDefault();
            const codeToCheck = currentCodes.reduce((p, curr) => p + curr, "");
            p.checkCode(codeToCheck);
          }}
        >
          Passer à l'étape suivante
        </button>
      ) : (
        <></>
      )}
    </>
  );
}

/*

        */
