import "../../styles/home.scss";
import { BackButtonFlex } from "../../parts/BackButton";
import { AdminRoute } from "../../redux/auth/AuthRoutes";
import { Expand } from "../../parts/Expand";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/ReduxStore";
import { useState } from "react";
import { RequestOptions } from "../../tools/RequestOptions";
import Config from "../../Config";
import { StoryStates, StoryStatusApi } from "../../shared/StoryElements";

enum ComponentState {
  start = 1,
  initializing,
  initialized,
}

export function AdminExplorer() {
  const [compState, setCompState] = useState<ComponentState>(
    ComponentState.start
  );
  const [allCodes, setCodes] = useState<
    (StoryStatusApi & { identifier: string })[]
  >([]);

  const dispatch = useDispatch<AppDispatch>();

  function stateToString(i: Number) {
    switch (i) {
      case StoryStates.COMMAND:
        return "COMMANDE";
      case StoryStates.SENT:
        return "ENVOYE";
      case StoryStates.ACTIVATED:
        return "EN COURS";
      case StoryStates.DONE:
        return "FINI";
    }
  }

  if (compState === ComponentState.start) {
    setCompState(ComponentState.initializing);
    fetch(Config.endpoint + "admin/status/explorer", RequestOptions())
      .then(async (response) => {
        if (response.status === 200) {
          const result: (StoryStatusApi & { identifier: string })[] =
            await response.json();
          setCodes(result);
        } else {
          alert(`Status: ${response.status}`);
        }

        setCompState(ComponentState.initialized);
      })
      .catch((e) => alert(e));
  }

  async function changeStep(status: StoryStatusApi, diff: number) {
    if (!window.confirm(`Valider changement de ${status.code} de ${diff} ?`)) {
      return;
    }

    if (!status.step) {
      alert("No step index");
      return;
    }

    const response = await fetch(
      Config.endpoint + "admin/status/changeStep",
      RequestOptions({
        method: "POST",
        body: JSON.stringify({
          statusCode: status.code,
          stepIdx: status.step?.index + diff,
        }),
      })
    );

    if (response.status === 200) {
      // Remove from code
      window.location.reload();
    } else {
      console.error(response.status);
      alert(`Status: ${response.status}`);
    }
  }

  async function changeFull(status: StoryStatusApi) {
    if (!window.confirm(`Valider changement de fullness de ${status.code} ?`)) {
      return;
    }

    if (!status.step) {
      alert("No step index");
      return;
    }

    const response = await fetch(
      Config.endpoint + "admin/status/fullOnWin",
      RequestOptions({
        method: "POST",
        body: JSON.stringify({
          statusCode: status.code,
          fullOnWin: !status.fullOnWin,
        }),
      })
    );

    if (response.status === 200) {
      // Remove from code
      window.location.reload();
    } else {
      console.error(response.status);
      alert(`Status: ${response.status}`);
    }
  }

  return (
    <AdminRoute>
      <div className="columns">
        <div className="is-fullwidth column">
          <h3 style={{ margin: "auto", fontWeight: "bold" }}>Explorer</h3>
        </div>
      </div>
      <div className="columns column" style={{ flexGrow: 0 }}>
        <table
          className="table is-striped is-hoverable"
          style={{ margin: "auto", borderRadius: "15px" }}
        >
          <thead>
            <tr>
              <th>#</th>
              <th>
                <abbr>Code</abbr>
              </th>
              <th>Identifier</th>
              <th>City</th>
              <th>State</th>
              <th>Step</th>
              <th>Full Price</th>
              <th>Steps</th>
              <th>Updated</th>
            </tr>
          </thead>
          <tbody>
            {allCodes.map((element, i) => (
              <tr>
                <th>{i}</th>
                <th>{element.code}</th>
                <th>{element.identifier}</th>
                <th>{element.story?.city}</th>
                <th>{stateToString(element.state)}</th>
                <th>{element.step?.name}</th>
                <th style={{ textAlign: "center" }}>
                  <input
                    type="checkbox"
                    checked={element.fullOnWin}
                    onClick={async (e) => {
                      e.preventDefault();
                      await changeFull(element);
                    }}
                  />
                </th>
                <th style={{ display: "flex", gap: "10px" }}>
                  <button
                    className="button is-family-monospace is-red is-active is-text-white"
                    onClick={async (e) => {
                      e.preventDefault();
                      await changeStep(element, -1);
                    }}
                  >
                    -
                  </button>
                  <button
                    className="button is-family-monospace is-active"
                    onClick={async (e) => {
                      e.preventDefault();
                      await changeStep(element, +1);
                    }}
                  >
                    +
                  </button>
                </th>
                <th>
                  {element.lastStepDate
                    ? element.lastStepDate?.substring(0, 10)
                    : "NONE"}
                </th>

                {
                  // <td>{JSON.stringify(element)}</td>
                }
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Expand />
      <BackButtonFlex />
    </AdminRoute>
  );
}
