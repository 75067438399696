import "../MessagesBase.scss";
import { BaseMessage } from "../BaseMessage";

export function LyonMessage1Alpha() {
  return (
    <BaseMessage title="Premier Message">
      <p>
        Félicitations,
        <br /> vos déductions semblent exactes.
      </p>
      <p>
        Le téléphone de HENRI a bien été détecté vers la station "Hôtel de Ville
        - Louis Pradel" le jour de sa disparition. Il semble s'être arrêté
        plusieurs minutes immédiatemment à la sortie de métro située derrière
        l'hôtel de ville.
      </p>
      <p>
        Nos services ont intercepté des messages qu'il a reçu à ce moment là
        mais ils sont chiffrés. Peut-être qu'en vous rendant sur place pour
        résoudre cette histoire de Gardienne vous trouverez le code utilisé.
      </p>
      <p>
        Vous pouvez retrouvez vos découvertes et nos communications dans la
        section "Messages" de votre espace.
      </p>
      <p>
        <br />
      </p>
      <p>
        Courage,
        <br />
        <span className="italic bold">La Compagnie</span>
      </p>
    </BaseMessage>
  );
}
