import { createAction, createReducer } from "@reduxjs/toolkit";

export enum TutorialSteps {
    Application = 1,
    Navbar,
    Parameters,
    End
}

export interface TutorialState {
    step?: TutorialSteps | null
}

export const setTutorialSteps = createAction<TutorialSteps>("setTutorialSteps");
export const startTutorialAction = createAction("startTutorialAction");
export const nextTutorialAction = createAction("nextTutorialAction");
export const unsetTutorialSteps = createAction("unsetTutorialSteps");

export const TutorialReducer = createReducer<TutorialState>(
    { step: null },
    (builder) => {
        builder
            .addCase(setTutorialSteps, (state, action) => {
                state.step = action.payload;
            })
            .addCase(unsetTutorialSteps, (state, _a) => {
                state.step = null;
            })
            .addCase(startTutorialAction, (state, _a) => {
                state.step = 1;
            })
            .addCase(nextTutorialAction, (state, _a) => {
                if (state.step !== TutorialSteps.End) {
                    state.step = state.step ? state.step + 1 : TutorialSteps.Navbar;
                }
            });

    }
);
