import "../../styles/home.scss";
import { BackButtonFlex } from "../../parts/BackButton";
import { AdminRoute } from "../../redux/auth/AuthRoutes";
import { Expand } from "../../parts/Expand";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/ReduxStore";
import { useState } from "react";
import { RequestOptions } from "../../tools/RequestOptions";
import Config from "../../Config";
import { StoryStatusApi } from "../../shared/StoryElements";
import { useCustomDocumentsArray } from "../../documents/DocumentsElements";
import { LinkListElement } from "../LinkListPage";
import { setAdminStatus, unsetAdminStatus } from "../../redux/admin/AdminState";

enum ComponentState {
  start = 1,
  initializing,
  initialized,
}

export function AdminUnsent() {
  const [currentCode, setCurrentCode] = useState<StoryStatusApi | undefined>();
  const [orderIdentifier, setOrderIdentifier] = useState<string>("");
  const [compState, setCompState] = useState<ComponentState>(
    ComponentState.start
  );
  const [allCodes, setCodes] = useState<StoryStatusApi[]>([]);
  const availablesDocuments = useCustomDocumentsArray(currentCode);
  const documentsList = availablesDocuments
    ? availablesDocuments.map((d) => d.infos)
    : undefined;

  const dispatch = useDispatch<AppDispatch>();

  function addToCode(newCode: StoryStatusApi) {
    setCodes([...allCodes, newCode]);
  }

  async function createNew() {
    const response = await fetch(
      Config.endpoint + "admin/status/create",
      RequestOptions({
        method: "POST",
      })
    );

    if (response.status === 200) {
      const result: StoryStatusApi = await response.json();
      addToCode(result);
    } else {
      console.error(response.status);
      alert(`Status: ${response.status}`);
    }
  }

  async function markSent() {
    if (!currentCode || !orderIdentifier) {
      alert("Mussing code or identifier");
      return;
    }

    const response = await fetch(
      Config.endpoint + "admin/status/markSent",
      RequestOptions({
        method: "POST",
        body: JSON.stringify({
          statusCode: currentCode.code,
          orderIdentifier: orderIdentifier,
        }),
      })
    );

    if (response.status === 200) {
      // Remove from code
      var index = allCodes.findIndex((looked) => looked === currentCode);
      setCodes(allCodes.toSpliced(index, 1));
      setCurrentCode(undefined);
    } else {
      console.error(response.status);
      alert(`Status: ${response.status}`);
    }
  }

  if (compState === ComponentState.start) {
    setCompState(ComponentState.initializing);
    fetch(Config.endpoint + "admin/status/unsent", RequestOptions())
      .then(async (response) => {
        if (response.status === 200) {
          const result: StoryStatusApi[] = await response.json();
          setCodes(result);
        } else {
          alert(`Status: ${response.status}`);
        }

        setCompState(ComponentState.initialized);
      })
      .catch((e) => alert(e));
  }

  return (
    <AdminRoute>
      <div className="columns">
        <div className="is-fullwidth column">
          <h3 style={{ margin: "auto", fontWeight: "bold" }}>
            Commandes Non Envoyées
          </h3>
        </div>
      </div>
      <div className="columns column" style={{ flexGrow: 0 }}>
        <button
          type="submit"
          className="is-fullwidth button is-active"
          onClick={async (e) => {
            e.preventDefault();

            await createNew();
          }}
          disabled={compState < ComponentState.initialized}
        >
          Créer une nouvelle
        </button>
      </div>
      <div className="columns column" style={{ flexGrow: 0 }}>
        <select
          className="is-fullwidth"
          name="codeSelect"
          style={{ width: "100%", padding: "0.55rem 1rem" }}
          onChange={(e) => {
            e.preventDefault();

            if (e.target.value === "not_defined") {
              setCurrentCode(undefined);
              dispatch(unsetAdminStatus());
            } else {
              setCurrentCode(allCodes[+e.target.value]);
              setOrderIdentifier("");
              dispatch(setAdminStatus(allCodes[+e.target.value]));
            }
          }}
        >
          <option value={"not_defined"}>--</option>
          {allCodes.map((element, i) => (
            <option value={i} key={i}>
              {element.code}
            </option>
          ))}
        </select>
      </div>
      <div className="columns column field has-addons" style={{ flexGrow: 0 }}>
        <p className="control" style={{ flexGrow: 6 }}>
          <input
            className="input is-active"
            type="text"
            value={orderIdentifier}
            onChange={(e) => setOrderIdentifier(e.target.value)}
            placeholder="Identifier"
            disabled={!currentCode}
          />
        </p>
        <p className="control" style={{ width: "20%" }}>
          <button
            type="submit"
            className="is-fullwidth button is-active"
            onClick={async (e) => {
              e.preventDefault();
              await markSent();
            }}
            disabled={compState < ComponentState.initialized || !currentCode}
          >
            Marquer comme Envoyé
          </button>
        </p>
      </div>
      <div className="columns">
        <div
          className="is-fullwidth column"
          style={{ flexDirection: "column", gap: "1rem" }}
        >
          <h2
            style={{ margin: "auto", textTransform: "none", marginTop: "1rem" }}
          >
            {currentCode !== undefined
              ? `Documents ${currentCode?.code}`
              : "Sélectionner un statut"}
          </h2>
          {currentCode !== undefined ? (
            <LinkListElement baseUrl="document" links={documentsList} />
          ) : (
            <></>
          )}
        </div>
      </div>
      <Expand />
      <BackButtonFlex />
    </AdminRoute>
  );
}
