import { PropsWithChildren } from "react";

export function Popup({
  children,
  popupStateEditor,
}: PropsWithChildren<{
  popupStateEditor: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}>) {
  const [popupVisible, setPopupVisible] = popupStateEditor;
  const crossSize = "2.5rem";
  const sizeStyle = (size: string | number): React.CSSProperties => {
    return {
      height: size,
      width: size,
      minWidth: size,
      minHeight: size,
      maxHeight: size,
      maxWidth: size,
    };
  };

  return (
    <div className={"modal " + (popupVisible ? "is-active" : "")}>
      <div
        className="modal-background"
        onClick={(e) => {
          e.preventDefault();
          setPopupVisible(!popupVisible);
        }}
      ></div>
      <div className="modal-content">
        <div className="box">{children}</div>
      </div>
      <button
        className="modal-close button is-rounded navbar-button"
        aria-label="close"
        onClick={(e) => {
          e.preventDefault();
          setPopupVisible(!popupVisible);
        }}
        style={{
          fontWeight: 800,
          ...sizeStyle(crossSize),
        }}
      ></button>
    </div>
  );
}
