import { useState } from "react";
import { validateEmail } from "../tools/TextTools";
import Config from "../Config";
import { RequestOptions } from "../tools/RequestOptions";
import { useNavigate } from "react-router";
import { Expand } from "../parts/Expand";

function UnsubscribeNewsletterPage() {
  const ErrorMessages: { [key: string]: string } = {
    INVALID_EMAIL: "Le mail indiqué est invalide",
    UNDEFINED_ERROR: "Une erreur est survenue",
    OK: "Désinscription validée",
  };

  const navigate = useNavigate();
  const [emailValue, setEmailValue] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalErrorAndText, setModalErrorAndText] = useState({
    error: false,
    text: "",
  });

  async function sendEmail() {
    if (!validateEmail(emailValue)) {
      setModalErrorAndText({ error: true, text: ErrorMessages.INVALID_EMAIL });
      setModalOpen(true);
      return;
    }

    const response = await fetch(
      Config.endpoint + "newsletter/unsubscribe",
      RequestOptions({
        method: "POST",
        body: JSON.stringify({ email: emailValue }),
      })
    );

    if (response.status === 202) {
      setModalErrorAndText({
        error: false,
        text: ErrorMessages.OK,
      });
      setModalOpen(true);
    } else {
      setModalErrorAndText({
        error: true,
        text: ErrorMessages.UNDEFINED_ERROR,
      });
      setModalOpen(true);
    }
  }

  return (
    <section className="section is-mobile is-centered bottomPart appear">
      <div className="comingSoon" style={{ justifyContent: "unset" }}>
        <div className="tagline cities" style={{ marginBottom: "0.2rem" }}>
          Vous désinscire de notre Newsletter ?
        </div>
        <div className="columns is-mobile is-centered">
          <div className="column is-narrow is-centered form-email">
            <form
              className="field has-addons"
              onSubmit={(e) => {
                e.preventDefault();
                sendEmail();
              }}
              style={{ marginTop: "0.1rem" }}
            >
              <div className="control">
                <input
                  className="input is-medium"
                  type="email"
                  size={30}
                  value={emailValue}
                  onChange={(e) => setEmailValue(e.target.value)}
                  name="email"
                  placeholder="Entrez votre email"
                />
              </div>
              <div className="control">
                <button className="button is-danger is-medium" type="submit">
                  Désinscription
                </button>
                <div
                  id="newsletter-modal"
                  className={`modal is-${
                    modalErrorAndText.error ? "error" : "ok"
                  } ${isModalOpen ? " is-active" : ""}`}
                  onClick={() => setModalOpen(false)}
                  style={{ textAlign: "center" }}
                >
                  <div className="modal-background"></div>

                  <div className="modal-content">
                    <div className="box">
                      <p>{modalErrorAndText.text}</p>
                    </div>
                  </div>

                  <button
                    className="modal-close is-large"
                    aria-label="close"
                  ></button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <button
        className="button is-active is-rounded"
        style={{ margin: "2rem auto 0 auto", display: "block" }}
        onClick={(e) => {
          e.preventDefault();
          navigate("/");
        }}
      >
        Retour à l'accueil
      </button>
      <Expand />
    </section>
  );
}

export default UnsubscribeNewsletterPage;
