import { TIME_BETWEEN_CLUES_MS } from "../../../../shared/StoryElements";

export const CLUE_POPUP_NEXT_BUTTON_COLORS = { active: "#bbb", inactive: "#888" };

export function useTimerPercent(startDate: Date, durationMn: number): number {
    const [percent, setPercent] = useState<number>(-1);
    const interval = useRef<NodeJS.Timer | null>(null);

    useEffect(() => {
        if (percent < 100) {
            interval.current = setInterval(() => {
                const current = new Date().getTime();
                const start = startDate.getTime();
                const durationMs = durationMn * 60 * 1000;

                setPercent((100 * (current - start)) / durationMs);
            }, 1000);
        } else {
            if (interval.current) {
                clearInterval(interval.current);
            }
            interval.current = null;
        }

        return () => {
            if (interval.current) {
                clearInterval(interval.current);
            }
        };
    }, [startDate, durationMn, percent, setPercent]);

    return percent;
}

export function getTimePercent(
    current: Date,
    previous: Date,
    duration = TIME_BETWEEN_CLUES_MS
) {
    return Math.trunc(
        (100 * (current.getTime() - previous.getTime())) / duration
    );
}

export function getClueButtonPercentedBackground(
    colors: { active: string; inactive: string },
    percent: number
) {
    if (percent > 0 && percent < 1) {
        percent *= 100;
    }

    return {
        backgroundImage: `linear-gradient(0.25turn, ${colors.active} ${percent}%, ${colors.inactive} ${percent}%)`,
    };
}