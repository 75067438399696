import "../MessagesBase.scss";
import { BaseMessage } from "../BaseMessage";

export function LyonMessage6Stars() {
  return (
    <BaseMessage title="Dernière Tâche">
      <p>
        Merci à vous. <br />
        Nous avons pu accéder aux documents contenus sur la clé. Il s'agit
        d'informations inestimables pour ce que nous faisons. Nous avons
        cependant encore besoin de vous pour retrouver notre agent.
      </p>
      <p>
        L'accès à la fin de la note trouvée chez HENRI vous a donc été accordé.
        Nos services ont également ajouté une image qui était cachée parmi les
        données sur la clé USB.
      </p>
      <p>
        Quand vous aurez trouvé le lieu de leur rencontre, nous nous chargerons
        d'intervenir.
      </p>
      <p>
        <br />
      </p>
      <p>
        Terminez la mission,
        <br />
        <span className="italic bold">La Compagnie</span>
      </p>
    </BaseMessage>
  );
}
