import { useEffect } from "react";
import ImagePart from "./ImagesPart";
import NewsletterForm from "./NewsletterForm";

interface Props {
  useDelay: boolean;
}

function Landing({ useDelay }: Props) {
  useEffect(() => {
    document.title = "La Compagnie";
  }, []);

  return (
    <>
      <ImagePart useDelay={useDelay} />
      <NewsletterForm useDelay={useDelay} />
    </>
  );
}

export default Landing;
