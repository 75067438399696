import { useNavigate } from "react-router";
import "../styles/concept.scss";
import FirstImg from "../imgs/concept/Square_Recevez.jpg";
import SecondImg from "../imgs/concept/Square_Explorez.jpg";
import ThirdImg from "../imgs/concept/Square_Resolvez.jpg";
import { Expand } from "../parts/Expand";

export function ConceptPage() {
  const navigate = useNavigate();

  return (
    <>
      <div className="columns" style={{ flexGrow: 1 }}>
        <div className="column is-half-desktop is-offset-one-quarter-desktop is-centered concept-container">
          <div className="concept-line">
            <div className="image">
              <img src={FirstImg} alt="Un courrier"></img>
            </div>
            <div className="concept-body">
              <h1 className="concept-header">1. Recevez</h1>
              <p>
                Recevez chez vous un courrier d'une étrange Compagnie vous
                demandant de retrouver un de leurs agents dans votre ville.
              </p>
            </div>
          </div>
          <div className="concept-line">
            <div className="concept-body">
              <h1 className="concept-header">2. Explorez</h1>
              <p>
                Vous devrez suivre la piste à travers les rues en suivant les
                messages codés et les différentes consignes sur le site web.
              </p>
            </div>
            <div className="image">
              <img src={SecondImg} alt="Un bâtiment"></img>
            </div>
          </div>
          <div className="concept-line">
            <div className="image">
              <img
                src={ThirdImg}
                alt="Un courrier ouvert révélant une lettre"
              ></img>
            </div>
            <div className="concept-body">
              <h1 className="concept-header">3. Résolvez</h1>
              <p>
                Différentes énigmes mêlant bâtiments réels et documents
                fictionnels vous feront découvrir la Compagnie.
              </p>
            </div>
          </div>
          <button
            className="button is-active is-rounded"
            style={{ margin: "2rem auto 0 auto", display: "block" }}
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
          >
            Retour à l'accueil
          </button>
          <Expand />
        </div>
      </div>
    </>
  );
}
