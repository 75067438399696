import Explore from "./imgs/explore.png";
import Letter from "./imgs/letter.png";
import Solve from "./imgs/solve.png";
import { CSSProperties } from "react";
import LandingConstants from "./LandingConstants";
import { Link } from "react-router-dom";

interface Props {
  useDelay: boolean;
}

function ImagePart({ useDelay }: Props) {
  const delay =
    LandingConstants.baseDelay +
    LandingConstants.tagLineDuration +
    LandingConstants.taglineDelay;

  let style: CSSProperties = {
    WebkitAnimationDelay: delay + "s",
    animationDelay: delay + "s",
    animationDuration: LandingConstants.imageDuration + "s",
    WebkitAnimationDuration: LandingConstants.imageDuration + "s",
  };

  return (
    <Link to="/concept">
      <section
        className="section columns images is-centered appear"
        id="imagesContainer"
        style={useDelay ? style : {}}
      >
        <figure className="image miniature column">
          <img src={Letter} alt="Une lettre" />
          <div className="imgBody">
            <h2>Recevez</h2>
            <p>
              Un courrier
              <br /> vous indique
              <br /> où démarrer
            </p>
          </div>
        </figure>
        <figure className="image miniature column">
          <img src={Explore} alt="Personne dans une rue" />
          <div className="imgBody">
            <h2>Explorez</h2>
            <p>
              Enquêtez
              <br /> à travers
              <br /> la ville
            </p>
          </div>
        </figure>
        <figure className="image miniature column">
          <img src={Solve} alt="Personne réflechissant" />
          <div className="imgBody">
            <h2>Résolvez</h2>
            <p>
              Découvrez
              <br /> qui en veut à<br /> la compagnie
            </p>
          </div>
        </figure>
      </section>
    </Link>
  );
}

export default ImagePart;
