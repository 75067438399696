import "../styles/LinkListPage.scss";
import { useDocumentsArray } from "./DocumentsElements";
import { LinkListPage, LinkListRoute } from "../pages/LinkListPage";

export function DocumentsApp() {
  const availablesDocuments = useDocumentsArray();

  const documentsList = availablesDocuments
    ? availablesDocuments.map((d) => d.infos)
    : undefined;

  return (
    <LinkListPage title="Documents" baseUrl="document" links={documentsList} />
  );
}

export function DocumentsRoute() {
  const array = useDocumentsArray();
  return <LinkListRoute array={array} />;
}
