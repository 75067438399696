import "../MessagesBase.scss";
import { BaseMessage } from "../BaseMessage";

export function LyonMessage7LaFin() {
  return (
    <BaseMessage title="Remerciements">
      <p>Félicitations, vous avez réussi.</p>
      <p>Ainsi ils s'étaient donnés rendez-vous vers la Part-Dieu.</p>
      <p>
        Nos équipes vont se charger de suivre les signaux qu'ils ont pu y
        laisser. Il est cependant fort possible que nos recherches nous mène à
        la gare, si c'est le cas HENRI restera sans doute introuvable.
      </p>
      <p>Nous allons nous concentrer sur cette prétendue source.</p>
      <p>
        <br />
      </p>
      <p>
        Merci de nous avoir aidé dans cette enquête. Nous vous recontacterons si
        nécessaire.
      </p>
      <p>
        <br />
      </p>
      <p>
        <span className="italic bold">La Compagnie</span>
      </p>
    </BaseMessage>
  );
}
