import { useEffect, useState } from "react";
import { LiveAudioVisualizer } from "react-audio-visualize";
import { Popup } from "../../../../parts/Popup";

function getWhiteNoise(
  audioContext: AudioContext,
  volume: number = 1
): AudioNode {
  var bufferSize = 4096;
  var whiteNoise = audioContext.createScriptProcessor(bufferSize, 1, 1);
  whiteNoise.onaudioprocess = function (e) {
    var output = e.outputBuffer.getChannelData(0);
    for (var i = 0; i < bufferSize; i++) {
      output[i] = (Math.random() * 2 - 1) * volume;
    }
  };

  return whiteNoise;
}

export default function FrequencyPlayer({
  audioBlob,
  audioDescription,
  playing,
  onLoopEnd,
}: {
  audioBlob: Blob | null;
  audioDescription?: string;
  playing: boolean;
  onLoopEnd?: () => void;
}) {
  const [showTranscript, setShowTranscript] = useState(false);
  const [currentAudioElement, setAudioElement] =
    useState<HTMLAudioElement | null>();
  const [currentAudioSource, setAudioSource] = useState<AudioNode | null>();

  const [currentMediaRecorder, setMediaRecorder] =
    useState<MediaRecorder | null>();
  const [audioContext] = useState(new AudioContext());

  function stopSound() {
    if (currentAudioElement != null) {
      currentAudioElement.pause();
    }

    if (currentAudioSource != null) {
      currentAudioSource.disconnect();
    }

    if (audioContext.state === "running") {
      audioContext.suspend();
    }
  }

  function linkSound(mp3AudioSource: AudioNode) {
    const mp3AudioDestination = audioContext.createMediaStreamDestination();
    mp3AudioSource.connect(mp3AudioDestination);
    mp3AudioSource.connect(audioContext.destination);

    const nextMediaRecorder = new MediaRecorder(mp3AudioDestination.stream);
    nextMediaRecorder.ondataavailable = (ev) => {
      nextMediaRecorder.start();
    };
    nextMediaRecorder.start();

    setMediaRecorder(nextMediaRecorder);
    setAudioSource(mp3AudioSource);
  }

  useEffect(() => {
    if (playing) {
      stopSound();

      if (
        audioContext.state === "suspended" ||
        audioContext.state === "running"
      ) {
        audioContext.resume();
      }

      if (audioBlob) {
        const nextAudio = new Audio(URL.createObjectURL(audioBlob));
        nextAudio.loop = true;
        nextAudio.play();

        nextAudio.onseeked = (e) => {
          if (onLoopEnd) {
            onLoopEnd();
          }
        };

        setAudioElement(nextAudio);
        nextAudio.onloadeddata = (ev) =>
          linkSound(audioContext.createMediaElementSource(nextAudio));
      } else {
        linkSound(getWhiteNoise(audioContext, 0.2));
      }
    } else if (!playing) {
      stopSound();
    }

    return () => {
      stopSound();
    };
  }, [audioBlob, playing]);

  if (currentMediaRecorder && currentMediaRecorder.state !== "recording") {
    currentMediaRecorder.start();
  }

  return (
    <>
      <div
        className="audio-player-bar"
        style={{
          marginTop: "2rem",
          height: "12vh",
          width: "80vw",
          margin: "2rem auto 1em auto",
        }}
      >
        {currentMediaRecorder ? (
          <LiveAudioVisualizer
            mediaRecorder={currentMediaRecorder}
            width={600}
            height={75}
            barWidth={5}
            gap={2}
            barColor={"#f76565"}
            maxDecibels={100}
            fftSize={512}
          />
        ) : (
          <></>
        )}
      </div>
      {audioDescription && playing ? (
        <>
          <button
            className="button is-dark"
            onClick={(e) => {
              e.preventDefault();
              setShowTranscript(true);
              if (onLoopEnd && !showTranscript) {
                onLoopEnd();
              }
            }}
          >
            Lire Transcription
          </button>
          <Popup popupStateEditor={[showTranscript, setShowTranscript]}>
            <h3>Transcription</h3>
            {audioDescription}
          </Popup>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
