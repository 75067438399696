const helloSpeed = 0.15;
const companySpeed = 0.1;
const helloText: string = "Bonjour,";
const companyText: string = "Nous sommes la compagnie";

const baseDelay =
  helloSpeed * helloText.length + companySpeed * companyText.length + 0.2;

const LandingConstants = {
  helloSpeed,
  companySpeed,
  helloText,
  companyText,
  baseDelay,
  tagLineDuration: 0.3,
  taglineDelay: 0.0,
  imageDuration: 1.0,
  imageDelay: -1.0,
  newsletterDuration: 1.0,
};

export default LandingConstants;
