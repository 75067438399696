import { Link, useNavigate, useParams } from "react-router-dom";
import { BackButtonFlex } from "../parts/BackButton";
import { ReactNode } from "react";
import { AuthRoute } from "../redux/auth/AuthRoutes";

export type LinkInfos = {
  url: string;
  text: string;
};

export type LinkInfosWithElement = {
  infos: LinkInfos;
  element: ReactNode;
};

export function useLinkListAsDictionary(
  array: LinkInfosWithElement[] | undefined
): {
  [url: string]: ReactNode;
} {
  if (!array) {
    return {};
  } else {
    const dict: { [url: string]: ReactNode } = {};
    for (var v of array) {
      dict[v.infos.url] = v.element;
    }
    return dict;
  }
}

export function LinkListPage(props: {
  title: string;
  baseUrl: string;
  links: LinkInfos[] | undefined;
}) {
  return (
    <>
      <div className="block-content expand">
        <h2 className="app-title link-list is-fullwidth">{props.title}</h2>

        <div className="link-list-ul is-fullwidth text-centered">
          <LinkListElement baseUrl={props.baseUrl} links={props.links} />
        </div>
      </div>
      <BackButtonFlex />
    </>
  );
}

export function LinkListElement(props: {
  baseUrl: string;
  links: LinkInfos[] | undefined;
}) {
  return props.links ? (
    props.links.map((linkInfos, index) => (
      <Link
        className="button"
        to={`/${props.baseUrl}/${linkInfos.url}`}
        key={index}
      >
        {linkInfos.text}
      </Link>
    ))
  ) : (
    <></>
  );
}

export function LinkListRoute(props: {
  array: LinkInfosWithElement[] | undefined;
}) {
  const availables = useLinkListAsDictionary(props.array);
  const navigate = useNavigate();

  const { currentUrl } = useParams();

  if (!currentUrl || currentUrl.length < 1) {
    navigate("/");
    return <></>;
  }

  return (
    <AuthRoute>
      {!availables[currentUrl] ? <></> : availables[currentUrl]}
    </AuthRoute>
  );
}
