import { useState, useEffect, ReactNode } from "react";

type WriterInfos = { element: ReactNode; isDone: boolean };

function StringWriter(
  speed: number,
  targetText: string,
  canStart: boolean,
  delay?: number,
  takeSpace?: boolean
): WriterInfos {
  const [currentText, setCurrentText] = useState<string>("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (canStart && currentIndex < targetText.length) {
      timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + targetText[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, speed + (delay !== undefined && currentIndex === 0 ? delay : 0));
    }

    return () => clearTimeout(timeout);
  }, [currentIndex, delay, speed, targetText, canStart]);

  const isWhitespace = (str: string) => /\S/.test(str);

  return {
    element:
      currentText.length > 0 && isWhitespace(currentText)
        ? currentText
        : takeSpace
        ? "\u00A0"
        : "",
    isDone: currentIndex >= targetText.length,
  };
}

function TypeWriterRecursive(
  speed: number,
  canStart: boolean,
  children?: ReactNode,
  delay?: number
): WriterInfos {
  var currentDelay = delay;
  var currentCanStart = canStart;

  if (children === undefined || children === null) {
    return { element: <></>, isDone: true };
  } else if (
    typeof children === "string" ||
    typeof children === "number" ||
    typeof children === "boolean"
  ) {
    return StringWriter(speed, String(children), canStart, currentDelay);
  } else if (Array.isArray(children)) {
    var returnArray: ReactNode[] = [];

    for (const child of children) {
      const subStep = TypeWriterRecursive(
        speed,
        currentCanStart,
        child,
        currentDelay
      );
      currentDelay = 0;
      currentCanStart = subStep.isDone;
      returnArray.push(subStep.element);
    }
    return {
      element: returnArray,
      isDone: currentCanStart,
    };
  } else {
    const untyped = children as any;
    if (untyped.props.children !== undefined) {
      const subStep = TypeWriterRecursive(
        speed,
        currentCanStart,
        untyped.props.children,
        currentDelay
      );

      const returnElement = {
        ...untyped,
        ...{ props: { ...untyped.props, ...{ children: subStep.element } } },
      };

      return {
        element: returnElement,
        isDone: subStep.isDone,
      };
    } else {
      return {
        element: currentCanStart ? children : <></>,
        isDone: currentCanStart,
      };
    }
  }
}

export function TypeWriter(props: {
  speed: number;
  delay?: number;
  children?: ReactNode;
}) {
  if (props.speed > 0) {
    return TypeWriterRecursive(props.speed, true, props.children, props.delay)
      .element;
  } else {
    return props.children;
  }
}

export default TypeWriter;
