import { useCallback, useEffect, useRef, useState } from "react";

export function useTimerPercent(
  startDate: Date,
  durationMn: number,
  stepMs: number = 1000
): number {
  function getPercent() {
    const current = new Date().getTime();
    const start = startDate.getTime();
    const durationMs = durationMn * 60 * 1000;

    return (100 * (current - start)) / durationMs;
  }

  const [percent, setPercent] = useState<number>(getPercent());
  const interval = useRef<NodeJS.Timer | null>(null);

  if (Math.abs(percent - getPercent()) > 2) {
    setPercent(getPercent);
  }

  const getPercentCallback = useCallback(() => {
    const current = new Date().getTime();
    const start = startDate.getTime();
    const durationMs = durationMn * 60 * 1000;

    return (100 * (current - start)) / durationMs;
  }, [startDate, durationMn]);

  useEffect(() => {
    if (percent < 100) {
      interval.current = setInterval(() => {
        setPercent(getPercentCallback());
      }, stepMs);
    } else {
      if (interval.current) {
        clearInterval(interval.current);
      }
      interval.current = null;
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [startDate, durationMn, percent, setPercent, stepMs, getPercentCallback]);

  return percent;
}
