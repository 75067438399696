import { Link, useLocation, useNavigate } from "react-router-dom";
import LandingConstants from "../pages/landing/LandingConstants";
import HeaderTagline from "./HeaderTagline";
import LoginSvg from "../imgs/login.svg";
import { useEffect, useRef, useState } from "react";
import Config from "../Config";

export interface HeaderProps {
  useDelay: boolean;
  useTagline: boolean;
}

function Header({ useDelay, useTagline }: HeaderProps) {
  const fadeDuration = 2;
  var totalDelay = 0;

  const navigate = useNavigate();

  function fadeAnimation(text: string, delay: number) {
    var concatenated = [];
    for (let i = 0; i < text.length; i++) {
      var currentDelay = totalDelay + i * delay;
      concatenated.push(
        <span
          key={i}
          className={[
            useDelay ? "bounceInDown" : "",
            i === text.length - 1 ? " last-char" : "",
          ].join(" ")}
          style={
            useDelay
              ? {
                  animationDelay: currentDelay + "s",
                  WebkitAnimationDelay: currentDelay + "s",
                }
              : {}
          }
        >
          {text.charAt(i) === " " ? " " : text.charAt(i)}
        </span>
      );
    }

    totalDelay += text.length * delay;

    return concatenated;
  }

  const [loginVisible, setLoginVisible] = useState(false);
  const location = useLocation();

  const interval = useRef<Timer>(null);

  useEffect(() => {
    interval.current = setInterval(() => {
      setLoginVisible(true);
    }, 1000 * (LandingConstants.baseDelay - fadeDuration));

    return () => {
      clearInterval(interval.current);
    };
  });

  return (
    <>
      <section className="section">
        <Link to="/">
          <header style={{ paddingRight: "7vw" }}>
            <h1 className="bonjour">
              {fadeAnimation(
                LandingConstants.helloText,
                LandingConstants.helloSpeed
              )}
            </h1>
            <h1 className="compagnie">
              {fadeAnimation(
                LandingConstants.companyText,
                LandingConstants.companySpeed
              )}
            </h1>
          </header>
        </Link>
        {useTagline ? <HeaderTagline useDelay={useDelay} /> : <></>}
      </section>
      {Config.showLogin ? (
        <div
          id="login-capsule"
          className={useDelay && !loginVisible ? "invisible" : ""}
          style={{
            animationName: useDelay && loginVisible ? `fadeIn` : "",
            animationDuration:
              useDelay && loginVisible ? `${fadeDuration}s` : "",
            animationTimingFunction: "ease-in",
          }}
        >
          <button
            className="button is-rounded is-medium is-family-monospace"
            onClick={(e) => {
              e.preventDefault();
              if (location.pathname === "/login") {
                navigate(-1);
              } else {
                navigate("/login");
              }
            }}
          >
            {location.pathname === "/login" ? "Retour" : "Login"}
          </button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default Header;
