import Config from './.config.local.json'

type CityStripeConfig = {
    url: string,
    promoCode?: string
}

type ConfigType = {
    endpoint: string,
    mainUrl: string,
    showCodeButton?: boolean,
    stripe: {
        lyon?: CityStripeConfig
        fakeLyon?: CityStripeConfig
    }
    showLogin: boolean
    debug?: boolean
}

export default Config as ConfigType