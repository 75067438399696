import { HoldableButton } from "../../../../parts/HoldableButton";

export default function FrequencyControlBar({
  setCodeDiff,
  onAnalyze,
  analyzeText,
}: {
  setCodeDiff: (a: number) => void;
  onAnalyze: () => void;
  analyzeText?: string;
}) {
  return (
    <div className="control">
      <HoldableButton
        className="button size-fix is-active is-pale is-left"
        onEvent={() => {
          setCodeDiff(-100);
        }}
      >
        ◀◀
      </HoldableButton>
      <HoldableButton
        className="button size-fix is-active is-pale is-full"
        onEvent={() => {
          setCodeDiff(-1);
        }}
      >
        ◀
      </HoldableButton>

      <button
        className="button size-fix is-active is-pale is-full is-family-monospace"
        style={{
          borderLeft: "0.1rem solid green",
          borderRight: "0.1rem solid green",
          maxWidth: "unset",
          width: "unset",
        }}
        onClick={(e) => {
          e.preventDefault();
          onAnalyze();
        }}
      >
        {analyzeText ? analyzeText : "Analyser"}
      </button>
      <HoldableButton
        className="button size-fix is-active is-pale is-full"
        onEvent={() => {
          setCodeDiff(1);
        }}
      >
        ▶
      </HoldableButton>
      <HoldableButton
        className="button size-fix is-active is-pale is-right"
        onEvent={() => {
          setCodeDiff(100);
        }}
      >
        ▶▶
      </HoldableButton>
    </div>
  );
}
