import { CSSProperties } from "react";
import LandingConstants from "../pages/landing/LandingConstants";
import { splitToSpan } from "../tools/TextTools";

interface Props {
  useDelay: boolean;
}

function HeaderTagline({ useDelay }: Props) {
  const tagLineStyle: CSSProperties = {
    WebkitAnimationDelay: LandingConstants.baseDelay + "s",
    animationDelay: LandingConstants.baseDelay + "s",
    animationDuration: LandingConstants.tagLineDuration + "s",
    WebkitAnimationDuration: LandingConstants.tagLineDuration + "s",
  };
  return (
    <>
      <h2
        id="tagline-wide"
        className="tagline taglinePart appear"
        style={useDelay ? tagLineStyle : {}}
      >
        {splitToSpan("·Jeu d'enquêtes postales et locales·")}
      </h2>
      <div
        className="tagline-narrow-container taglinePart appear show"
        style={useDelay ? tagLineStyle : {}}
      >
        <h2 className="tagline tagline-narrow">
          {splitToSpan("Jeu d'enquêtes")}
        </h2>
        <h2 className="tagline tagline-narrow">
          {splitToSpan("Postales et locales")}
        </h2>
      </div>
    </>
  );
}

export default HeaderTagline;
