import { NavigateFunction } from "react-router";
import { StoryConstants } from "../../shared/StoryConstants";
import { StoryMainApi, StoryStatusApi } from "../../shared/StoryElements";
import { CodeFormInfos } from "./CodeFormInfos";
import { CodeFormNormal } from "./code_types/CodeFormNormal";
import { CodeFormStatue } from "./code_types/CodeFormStatue";
import { CodeFormGPS } from "./code_types/CodeFormGPS";
import { CodeRendezVous } from "./code_types/CodeRendezVous";
import { CodeFrequencies } from "./code_types/frequencies/CodeFrequencies";
import { CodeFormLinks } from "./code_types/CodeFormLinks";

import EndImagePath from "../../imgs/loin_cropped.png";
import { CodeFormLinkAndImage } from "./code_types/CodeFormLinkAndImage";

export function getCodeFormInfos(
  storyStatus: {
    story: StoryMainApi | undefined;
    step: { index: number } | undefined;
  } | null
): CodeFormInfos | undefined {
  var currentInfos: CodeFormInfos | undefined = undefined;
  const baseInfos: CodeFormInfos = {
    isCentered: true,
    type: "text",
    placeholder: "Code/Lieu",
    header: "Avez-vous du nouveau ?",
    subheader: "Veuillez rentrer le code pour la prochaine étape",
    formElement: CodeFormNormal,
  };

  if (storyStatus) {
    switch (storyStatus.story?.index) {
      case StoryConstants.Story.FIRST_MISSION:
        switch (storyStatus.step?.index) {
          case StoryConstants.Steps.FIND_ALPHA:
            currentInfos = {
              ...baseInfos,
              minLength: 3,
              placeholder: "Point ALPHA",
            };
            break;
          case StoryConstants.Steps.FIND_STATUE:
            currentInfos = {
              ...baseInfos,
              type: "number",
              formElement: CodeFormStatue,
              subheader: "Rendez-vous au point ALPHA et cherchez le code",
            };
            break;
          case StoryConstants.Steps.DAME_PERCHEE:
            currentInfos = {
              ...baseInfos,
              type: "number",
              formElement: CodeFormGPS,
              header: "La Dame Perchée",
              subheader:
                "Allez voir cette dame et donnez-nous leur lieu de rendez-vous.",
            };
            break;
          case StoryConstants.Steps.RENDEZ_VOUS:
          case StoryConstants.Steps.THE_SMS:
            currentInfos = {
              ...baseInfos,
              type: "number",
              formElement: CodeRendezVous,
              header: "La Conversation",
              subheader:
                "La source semble avoir rencontré HENRI au 5 rue Coustou",
              isCentered: false,
            };
            break;
          case StoryConstants.Steps.LA_PLACE:
            currentInfos = {
              ...baseInfos,
              type: "number",
              formElement: CodeFrequencies,
              header: "Fréquences",
              subheader: "Sélectionner une fréquence à déchiffrer",
              isCentered: false,
            };
            break;
          case StoryConstants.Steps.DEVOIR_MUT:
            currentInfos = {
              ...baseInfos,
              minLength: 4,
              placeholder: "Mot de passe du dossier",
              header: "La Clé USB",
              subheader: "Nous avons besoin de vous pour déchiffrer cette clé",
              formElement: CodeFormLinks,
              links: [
                {
                  to: "/home/messages/cleUsb",
                  str: "Message de la Compagnie",
                },
                {
                  to: "/document/stolen_message",
                  str: "Courrier de DANI à HENRI",
                },
                {
                  to: "/home/app/frequencies",
                  str: "Application Fréquences",
                },
              ],
            };
            break;
          case StoryConstants.Steps.STARS:
            currentInfos = {
              ...baseInfos,
              placeholder: "Lieu du rendez-vous",
              header: "Le Rendez-Vous",
              subheader: "Où l'informatrice a-t-elle rencontré Henri ?",
              formElement: CodeFormLinkAndImage,
              links: [
                {
                  to: "/document/stolen_message",
                  str: "Courrier de DANI à HENRI",
                },
              ],
              image: {
                src: EndImagePath,
                alt: "Un schéma trouvé sur la clé USB",
                subtitle: "Le schéma trouvé sur la clé",
              },
            };
            break;
        }
        break;
    }
  }

  return currentInfos;
}

export function onSuccessNavigation(
  status: StoryStatusApi,
  navigate: NavigateFunction
) {
  if (status.story?.index === StoryConstants.Story.FIRST_MISSION) {
    switch (status.step?.index) {
      case StoryConstants.Steps.FIND_STATUE:
        navigate("/home/messages/premierMessage?appear");
        break;
      case StoryConstants.Steps.DAME_PERCHEE:
        navigate("/home/messages/secondMessage?appear");
        break;
      case StoryConstants.Steps.RENDEZ_VOUS:
        navigate("/home/messages/rendezVous?appear");
        break;
      case StoryConstants.Steps.DEVOIR_MUT:
        navigate("/home/messages/cleUsb?appear");
        break;
      case StoryConstants.Steps.STARS:
        navigate("/home/messages/stars?appear");
        break;
      case StoryConstants.Steps.FIRST_END:
        navigate("/home");
        navigate("/home/messages/laFin?appear");
        break;
    }
  }
}

export const CodeFormsInfos = {
  LA_PLACE: getCodeFormInfos({
    story: {
      city: StoryConstants.City.LYON,
      index: StoryConstants.Story.FIRST_MISSION,
    },
    step: {
      index: StoryConstants.Steps.LA_PLACE,
    },
  }),
};
