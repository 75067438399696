import { Expand } from "../../parts/Expand";
import { BackButtonFlex } from "../../parts/BackButton";
import "../../styles/fireworks.scss";
import { Link } from "react-router-dom";

function CreditsApp() {
  const mail = "mailto";
  const part2 = ["noussommeslacompagnie", "fr"];
  const part1 = ["con", "tact"];
  const total = `${part1.join("")}@${part2.join(".")}`;

  return (
    <>
      <div className="pyro">
        <div className="before"></div>
        <div className="after"></div>
      </div>
      <div
        className="text-center"
        style={{
          flexGrow: 2,
          flexDirection: "column",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{ alignSelf: "center", opacity: 0.9 }}
          className="pyro-text"
        >
          <h1>Merci !</h1>
          <p>Vous avez terminé cette aventure de la Compagnie</p>
          <p>
            N'hésitez pas à envoyer vos retours à{" "}
            <Link
              to={`${mail}:${total}`}
              style={{ color: "unset", fontWeight: 600 }}
            >
              {total}
            </Link>
          </p>
          <p className="italic" style={{ marginTop: "1rem" }}>
            Remerciements aux testeurs et à tous les premiers joueurs
          </p>
          <p style={{ marginTop: "2rem" }}>
            Un jeu de
            <br />{" "}
            <Link
              to={`https://doublesept.fr`}
              style={{ color: "unset", fontWeight: 700 }}
            >
              DoubleSept
            </Link>
          </p>
        </div>
      </div>
      <Expand />
      <BackButtonFlex />
    </>
  );
}

export default CreditsApp;
