import "../MessagesBase.scss";
import { BaseMessage } from "../BaseMessage";

export function LyonMessage4GPS() {
  return (
    <BaseMessage title="Le Rendez-Vous">
      <p>
        OK.
        <br />
        Les coordonnées GPS correspondent à une cour située au{" "}
        <b> 5 rue Coustou</b>.
      </p>
      <p>
        Nous lançons l'analyse des échanges à cette adresse. Vous êtes situés à
        moins de 50m, rendez-vous sur place pendant l'analyse.
      </p>
      <p>
        Si vous vous retrouvez bloqué, la sortie de cette cour est au{" "}
        <b> 22 rue des Capucins</b>.
      </p>
      <p>
        <br />
      </p>
      <p>
        N'échouez pas,
        <br />
        <span className="italic bold">La Compagnie</span>
      </p>
    </BaseMessage>
  );
}
