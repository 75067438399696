import FileImg from "../../../imgs/file.svg";
import GeoImg from "../../../imgs/geo.svg";
import CameraImg from "../../../imgs/camera.svg";
import ChatImg from "../../../imgs/chats_dots.svg";
import LockImg from "../../../imgs/lock.svg";
import WaveImg from "../../../imgs/wave.svg";
import SmsImg from "../../../imgs/sms-organizer.svg";
import FilmImg from "../../../imgs/film.svg";
import "../../../styles/home.scss";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { useMessagesArray } from "../../messages/MessagesElements";
import { useDocumentsArray } from "../../../documents/DocumentsElements";
import { useDispatch, useSelector } from "react-redux";
import { unsetAdminStatus } from "../../../redux/admin/AdminState";
import { RootState } from "../../../redux/ReduxStore";
import { TutorialPopup } from "../../../parts/TutorialPopup";
import { TutorialSteps } from "../../../redux/tutorials/TutorialState";
import { StoryConstants } from "../../../shared/StoryConstants";
import { useUpdateShowClue } from "../../../redux/ShowClue";

type BodyItem = {
  img: string;
  alt: string;
  text: string;
  application: string;
  disabled?: boolean;
};

const BodyItems: { [name: string]: BodyItem } = {
  Documents: {
    img: FileImg,
    alt: "Dossier",
    text: "Documents",
    application: "documents",
  },
  Camera: {
    img: CameraImg,
    alt: "Appareil photo",
    text: "Caméra",
    application: "camera",
  },
  Chat: {
    img: ChatImg,
    alt: "Phylactère",
    text: "Messages",
    application: "messages",
  },
  Lock: {
    img: LockImg,
    alt: "Cadenas",
    text: "Code",
    application: "code",
  },
  LockSms: {
    img: SmsImg,
    alt: "Bulle conversation",
    text: "Echanges SMS",
    application: "code",
  },
  FrequencyCode: {
    img: WaveImg,
    alt: "Ondes Sinusoïdales",
    text: "Fréquences",
    application: "code",
  },
  FrequencyStandalone: {
    img: WaveImg,
    alt: "Ondes Sinusoïdales",
    text: "Fréquences",
    application: "frequencies",
  },
  FilmCredits: {
    img: FilmImg,
    alt: "Pellicule Cinéma",
    text: "Crédits",
    application: "credits",
  },
  PointAlpha: {
    img: GeoImg,
    alt: "Marque de localisation",
    text: "Point ALPHA",
    application: "code",
  },
};

function MenuButton(props: { item: BodyItem }) {
  const disabled = props.item.disabled;
  return (
    <Link
      to={`/home/app/${props.item.application}`}
      className={`button cartel cartel-home button-cartel ${
        disabled ? "button-disabled" : ""
      }`}
      onClick={disabled ? (event) => event.preventDefault() : undefined}
    >
      <img src={props.item.img} alt={props.item.alt} className="navbar-item" />
      <p>{props.item.text}</p>
    </Link>
  );
}

export function BodyFull() {
  const statusState = useSelector((state: RootState) => state.status);
  const hasMessages = useMessagesArray();
  const hasDocuments = useDocumentsArray();
  const dispatch = useDispatch();
  useUpdateShowClue(true);

  const items: BodyItem[] = [];

  const adminStatus = useSelector((state: RootState) => state.admin.status);
  if (adminStatus) {
    dispatch(unsetAdminStatus());
  }

  var mainApp: BodyItem | null = null;
  if (statusState.status?.step?.index) {
    if (statusState.status.step.index === StoryConstants.Steps.FIND_ALPHA) {
      mainApp = BodyItems.PointAlpha;
    } else if (
      statusState.status.step.index >= StoryConstants.Steps.RENDEZ_VOUS &&
      statusState.status.step.index <= StoryConstants.Steps.THE_SMS
    ) {
      mainApp = BodyItems.LockSms;
    } else if (
      statusState.status.step.index === StoryConstants.Steps.LA_PLACE
    ) {
      mainApp = BodyItems.FrequencyCode;
    } else if (
      statusState.status.step.index >= StoryConstants.Steps.FIRST_END
    ) {
      mainApp = BodyItems.FilmCredits;
    }
  }

  if (mainApp == null || mainApp === undefined) {
    mainApp = BodyItems.Lock;
  }

  items.push(mainApp);

  if (
    statusState.status?.step?.index &&
    statusState.status?.step?.index > StoryConstants.Steps.LA_PLACE &&
    statusState.status?.step?.index <= StoryConstants.Steps.DEVOIR_MUT
  ) {
    items.push(BodyItems.FrequencyStandalone);
  }

  if (hasMessages && hasMessages.length > 0) {
    items.push(BodyItems.Chat);
  } else {
    items.push({ disabled: true, ...BodyItems.Chat });
  }

  if (hasDocuments && hasDocuments.length > 0) {
    items.push(BodyItems.Documents);
  }

  return GenerateItemsButton(items);
}

function GenerateItemsButton(items: BodyItem[]) {
  // If less than three or odd number, generate full width item(s)
  var fullwidthItemsHtml = (
    items.length % 2 === 1 ? [items[0]] : items.length < 3 ? items : []
  ).map((item) => {
    return (
      <div className="columns" key={item.alt}>
        <div className="column is-fullwidth is-centered">
          <MenuButton item={item} />
        </div>
      </div>
    );
  });

  // Generate line of half width items if more than 2 elements
  var halfItems =
    items.length > 2
      ? items.length % 2 === 1
        ? items.slice(1, items.length)
        : items
      : [];

  var halfItemsHtml: ReactElement[] = [];
  for (var i = 0; i < halfItems.length / 2; i++) {
    halfItemsHtml.push(
      <div className="columns" key={i}>
        <div className="column is-half-desktop is-centered">
          <MenuButton item={halfItems[i * 2]} />
        </div>
        <div className="column is-half-desktop is-centered">
          <MenuButton item={halfItems[i * 2 + 1]} />
        </div>
      </div>
    );
  }

  return (
    <>
      {fullwidthItemsHtml}
      {halfItemsHtml}
      <TutorialPopup
        step={TutorialSteps.Application}
        style={{
          top: "50%",
          minWidth: "300px",
          width: "65vw",
          maxWidth: "95vw",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <p>
          <b>Bienvenue dans la Compagnie</b>
        </p>
        <p>Voici quelques informations sur l'utilisation de votre espace.</p>
        <p>
          Cette zone centrale vous permet de naviguer entre différentes
          sections, dont certaines se débloqueront durant l'aventure.
          <br /> Au départ, utilisez <b style={{ fontSize: "110%" }}> Code </b>
          pour communiquer le point ALPHA à la Compagnie.
        </p>
      </TutorialPopup>
    </>
  );
}
