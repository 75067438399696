import { useEffect, useState } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export function useBlob(url: string) {
  const [blob, setBlob] = useState<Blob | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(url);
        if (!response.ok)
          throw new Error(`Response not OK (${response.status})`);
        setBlob(await response.blob());
      } catch (ex) {
        console.error(ex instanceof Error ? ex : new Error(String(ex)));
      }
    })();
  }, [url]);

  return blob;
}
