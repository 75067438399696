import { PropsWithChildren, ReactNode } from "react";
import { LyonMissionOrder } from "./lyon_1_ordre_mission/LyonMissionOrder";
import { LyonTranscriptionDocument } from "./lyon_2_transcription/LyonTranscriptionDocument";
import { LyonMapDocument } from "./lyon_3_map/LyonMapDocument";
import { useSelector } from "react-redux";
import { RootState } from "../redux/ReduxStore";
import { LinkInfos, LinkInfosWithElement } from "../pages/LinkListPage";
import { StoryStatusApi } from "../shared/StoryElements";
import { StoryConstants } from "../shared/StoryConstants";
import { LyonStolenDocument } from "./lyon_4_stolen_message/LyonStolenDocument";

type CityDocuments = {
  mission_order: ReactNode;
  transcription: ReactNode;
  map: ReactNode;
  stolen_message: ReactNode;
};

export function DocumentPage({
  children,
  extraClass,
}: PropsWithChildren<{ extraClass?: string }>) {
  return (
    <div className="full-page">
      <div className={"a4unset page " + (extraClass ? extraClass : "")}>
        {children}
      </div>
    </div>
  );
}

export const DocumentsInfosList: { [s: string]: LinkInfos } = {
  mission_order: { url: "mission_order", text: "Ordre de Mission" },
  transcription: { url: "transcription", text: "Retranscription Téléphonique" },
  map: { url: "map", text: "Carte de la ville" },
  stolen_message: { url: "stolen_message", text: "Message de DANI à HENRI" },
};

const LyonDocuments: CityDocuments = {
  mission_order: <LyonMissionOrder />,
  transcription: <LyonTranscriptionDocument />,
  map: <LyonMapDocument />,
  stolen_message: <LyonStolenDocument />,
};

export function useDocumentsArray(): LinkInfosWithElement[] | undefined {
  const status = useSelector((state: RootState) => state.status.status);

  return useCustomDocumentsArray(status);
}

export function useCustomDocumentsArray(
  status: StoryStatusApi | null | undefined
) {
  const { city, storyIdx, stepIdx } = {
    city: status?.story?.city,
    storyIdx: status?.story?.index,
    stepIdx: status?.step?.index,
  };

  if (city === undefined || storyIdx === undefined || stepIdx === undefined) {
    return undefined;
  }
  const cityDocuments = LyonDocuments;

  const toReturn: LinkInfosWithElement[] = [];
  toReturn.push({
    infos: DocumentsInfosList.mission_order,
    element: cityDocuments.mission_order,
  });
  toReturn.push({
    infos: DocumentsInfosList.transcription,
    element: cityDocuments.transcription,
  });
  toReturn.push({
    infos: DocumentsInfosList.map,
    element: cityDocuments.map,
  });

  if (storyIdx === StoryConstants.Story.FIRST_MISSION) {
    if (city === StoryConstants.City.LYON) {
      if (stepIdx >= StoryConstants.Steps.DEVOIR_MUT) {
        toReturn.push({
          infos: DocumentsInfosList.stolen_message,
          element: cityDocuments.stolen_message,
        });
      }
    }
  }

  return toReturn;
}
