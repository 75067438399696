import { useSelector } from "react-redux";
import { LinkInfosWithElement } from "../LinkListPage";
import { StoryConstants } from "../../shared/StoryConstants";
import { LyonMessage1Alpha } from "./pages/LyonMessage1_Alpha";
import { LyonMessage2Gardiennes } from "./pages/LyonMessage2_Gardiennes";
import { LyonMessage4GPS } from "./pages/LyonMessage4_GPS";
import { LyonMessage3Perchee } from "./pages/LyonMessage3_Perchee";
import { CodeRendezVousPage } from "../code/code_types/CodeRendezVous";
import { LyonMessage5CleUsb } from "./pages/LyonMessage5_CleUsb";
import { RootState } from "../../redux/ReduxStore";
import { LyonMessage6Stars } from "./pages/LyonMessage6_Stars";
import { LyonMessage7LaFin } from "./pages/LyonMessage7_LaFin";

export function useMessagesArray(): LinkInfosWithElement[] | undefined {
  const { city, storyIdx, stepIdx } = useSelector((state: RootState) => {
    return {
      city: state.status.status?.story?.city,
      storyIdx: state.status.status?.story?.index,
      stepIdx: state.status.status?.step?.index,
    };
  });

  if (city === undefined || storyIdx === undefined || stepIdx === undefined) {
    return undefined;
  }

  const toReturn: LinkInfosWithElement[] = [];

  if (
    city === StoryConstants.City.LYON &&
    storyIdx === StoryConstants.Story.FIRST_MISSION
  ) {
    if (stepIdx >= StoryConstants.Steps.FIND_STATUE) {
      toReturn.push({
        infos: {
          url: "premierMessage",
          text: "Premier Message",
        },
        element: <LyonMessage1Alpha />,
      });
    }

    if (stepIdx >= StoryConstants.Steps.DAME_PERCHEE) {
      toReturn.push({
        infos: {
          url: "messageDechiffre",
          text: "Message de la Gardienne",
        },
        element: <LyonMessage2Gardiennes />,
      });

      toReturn.push({
        infos: {
          url: "secondMessage",
          text: "Second Message",
        },
        element: <LyonMessage3Perchee />,
      });
    }

    if (stepIdx >= StoryConstants.Steps.RENDEZ_VOUS) {
      toReturn.push({
        infos: {
          url: "rendezVous",
          text: "Le Rendez-vous",
        },
        element: <LyonMessage4GPS />,
      });
    }

    if (stepIdx >= StoryConstants.Steps.LA_PLACE) {
      toReturn.push({
        infos: {
          url: "laConversation",
          text: "La Conversation",
        },
        element: <CodeRendezVousPage />,
      });
    }

    if (stepIdx >= StoryConstants.Steps.DEVOIR_MUT) {
      toReturn.push({
        infos: {
          url: "cleUsb",
          text: "La Clé USB",
        },
        element: <LyonMessage5CleUsb />,
      });
    }

    if (stepIdx >= StoryConstants.Steps.STARS) {
      toReturn.push({
        infos: {
          url: "stars",
          text: "Dernière Tâche",
        },
        element: <LyonMessage6Stars />,
      });
    }

    if (stepIdx >= StoryConstants.Steps.FIRST_END) {
      toReturn.push({
        infos: {
          url: "laFin",
          text: "Remerciements",
        },
        element: <LyonMessage7LaFin />,
      });
    }
  }
  return toReturn;
}
