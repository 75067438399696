import { PropsWithChildren } from "react";
import { useSearchParams } from "react-router-dom";
import TypeWriter from "../../tools/TypeWriter";
import { BackButtonFlex } from "../../parts/BackButton";

export function BaseMessage({
  title,
  isMonospace,
  children,
}: PropsWithChildren & { title: string; isMonospace?: boolean }) {
  const [searchParams] = useSearchParams();
  const isAppear = searchParams.has("appear");
  return (
    <>
      <div
        className={`block-content expand message-app ${
          isAppear ? "appear" : ""
        }`}
      >
        <h2 className="app-title is-fullwidth">{title}</h2>
        <div
          className={"message-in " + (isMonospace ? "is-family-monospace" : "")}
        >
          <TypeWriter speed={isAppear ? 30 : 0} delay={1000}>
            {children}
          </TypeWriter>
        </div>
      </div>
      <BackButtonFlex customPath={isAppear ? "/home" : undefined} />
    </>
  );
}
