import "../MessagesBase.scss";
import { BaseMessage } from "../BaseMessage";

export function LyonMessage3Perchee() {
  return (
    <BaseMessage title="Vous êtes étonnant">
      <p>Votre talent nous étonne.</p>
      <p>
        Maintenant que vous avez déchiffré ce message vous allez devoir
        l'utiliser pour suivre la trace de l'agent HENRI.
      </p>
      <p>
        Nous supposons que cet "indicateur" cherchait en vérité à l'isoler avant
        de l'emmener dans un guet-apens.
      </p>
      <p>
        Nous supposons que suivre le message va vous permettre de préciser les
        coordonnées GPS.
      </p>
      <p>
        Dès que vous pensez les avoir, n'agissez pas seul et transmettez-les
        nous.
      </p>
      <p>
        <br />
      </p>
      <p>
        Continuez,
        <br />
        <span className="italic bold">La Compagnie</span>
      </p>
    </BaseMessage>
  );
}
