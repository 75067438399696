import "../../styles/home.scss";
import { BackButtonFlex } from "../../parts/BackButton";
import { AdminRoute } from "../../redux/auth/AuthRoutes";
import { Expand } from "../../parts/Expand";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/ReduxStore";
import { useState } from "react";
import { RequestOptions } from "../../tools/RequestOptions";
import Config from "../../Config";
import { StatusSliceActions } from "../../redux/status/StatusSlice";
import { Link } from "react-router-dom";

export function AdminPage() {
  const status = useSelector((state: RootState) => state.status.status);
  const [stepIndex, setStepIndex] = useState<string>("");
  const dispatch = useDispatch<AppDispatch>();

  const toPrint = {
    code: status?.code,
    stepIdx: status?.step?.index,
    clueIndex: status?.clueIndex,
  };

  async function changeStep() {
    const response = await fetch(
      Config.endpoint + "/user/status/changeStep",
      RequestOptions({
        method: "POST",
        body: JSON.stringify({ stepIdx: parseInt(stepIndex) }),
      })
    );

    if (response.status === 200) {
      const status = await response.json();
      dispatch(StatusSliceActions.stepChanged(status));
    } else {
      alert("Problème");
    }
  }

  return (
    <AdminRoute>
      <div className="columns">
        <div className="is-fullwidth column">
          <h3 style={{ margin: "auto", fontWeight: "bold" }}>
            Panneau Administration
          </h3>
        </div>
      </div>
      <div className="columns">
        <div className="is-fullwidth column" style={{ fontSize: "80%" }}>
          <p>Statut Actuel :</p>
          <pre style={{ marginLeft: "1rem", padding: 5, borderRadius: 5 }}>
            {JSON.stringify(toPrint, null, 2)}
          </pre>
        </div>
      </div>
      <div className="columns">
        <form
          id="stepForm"
          className="is-fullwidth column login-form"
          style={{ marginTop: 0 }}
          onSubmit={(e) => {
            e.preventDefault();
            changeStep();
          }}
        >
          <div className="field has-addons" style={{ flexGrow: 1 }}>
            <p className="control" style={{ flexGrow: 6 }}>
              <input
                className="input is-active"
                type="number"
                value={stepIndex}
                onChange={(e) => setStepIndex(e.target.value)}
                placeholder="Nouvelle Step"
              />
            </p>
            <p className="control" style={{ width: "20%" }}>
              <button
                type="submit"
                className="button is-active"
                style={{ width: "100%" }}
              >
                Modifier Step
              </button>
            </p>
          </div>
        </form>
      </div>
      <div className="columns column" style={{ flexGrow: 0 }}>
        <Link className="is-fullwidth button is-active" to={"/admin/unsent"}>
          Commandes Non Envoyées
        </Link>
      </div>
      <div className="columns column" style={{ flexGrow: 0 }}>
        <Link className="is-fullwidth button is-active" to={"/admin/explorer"}>
          Explorer les statuts
        </Link>
      </div>
      <div className="columns column" style={{ flexGrow: 0 }}>
        <button
          type="submit"
          className="is-fullwidth button is-active"
          disabled
        >
          Gérer Utilisateurs
        </button>
      </div>
      <Expand />
      <BackButtonFlex />
    </AdminRoute>
  );
}
