import { useDispatch } from "react-redux";
import LockImg from "../../../../imgs/lock.svg";
import {
  CLUE_POPUP_NEXT_BUTTON_COLORS,
  getClueButtonPercentedBackground,
} from "./ConstantsCluePopup";
import { ClueTimerInfos } from "./TimerCluePopup";
import { AppDispatch } from "../../../../redux/ReduxStore";
import { StatusThunk } from "../../../../redux/status/StatusThunk";

export const LockedClueButton = (
  idx: number,
  backgroundStyle?: {
    backgroundImage: string;
  },
  onClick?: null | (() => void)
) => (
  <p className="control" key={idx}>
    <button
      className="button is-fullwidth is-family-monospace is-inactive"
      style={backgroundStyle ? backgroundStyle : {}}
      onClick={(e) => {
        e.preventDefault();
        if (onClick) {
          onClick();
        }
      }}
    >
      <img src={LockImg} alt="Cadenas" className="lock" />
    </button>
  </p>
);

export const ClueButton = (
  idx: number,
  active: boolean,
  onClick?: null | (() => void)
) => (
  <p className="control" key={idx}>
    <button
      className={`button is-fullwidth is-family-monospace available ${
        active ? "is-active" : "is-inactive"
      }`}
      onClick={(e) => {
        e.preventDefault();
        if (onClick) {
          onClick();
        }
      }}
    >
      {idx}
    </button>
  </p>
);

export function NextClueButton(
  timerInfos: ClueTimerInfos,
  idx: number,
  setAskingForNewClue: (a: boolean) => any
) {
  const background = getClueButtonPercentedBackground(
    CLUE_POPUP_NEXT_BUTTON_COLORS,
    timerInfos.percent
  );

  if (!timerInfos.nextAvailable) {
    return LockedClueButton(idx, background);
  } else {
    return LockedClueButton(idx, background, () => {
      setAskingForNewClue(true);
    });
  }
}

export function AskForNewClue(props: {
  isLast: boolean;
  goBack: (v: boolean) => void;
}) {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <>
      <p className="is-family-monospace">
        Révéler {props.isLast ? "la solution" : "un nouvel indice"}
      </p>
      <div className="field yes-no">
        <p className="control">
          <button
            className={`button is-fullwidth is-family-monospace yes`}
            onClick={(e) => {
              e.preventDefault();
              dispatch(StatusThunk.newClue());
              props.goBack(true);
            }}
          >
            Oui
          </button>
        </p>
        <p className="control">
          <button
            className={`button is-fullwidth is-family-monospace no`}
            onClick={(e) => {
              e.preventDefault();
              props.goBack(false);
            }}
          >
            Non
          </button>
        </p>
      </div>
    </>
  );
}
